import { Button, Form } from "antd";
import styled from "styled-components";

export const ThemeForm = styled(Form)`
  .ant-form-item-explain-error {
    /* font-size: 1rem; // Adjust this size as needed */
  }
`;

export const SubmitButton = styled(Button)`
  border-radius: ${(props) => props?.theme?.token?.boxShadowNone};
  font-size: 1rem;
  width: 100%;
  height: 3rem;
  background-color: ${(props) =>
    props?.remove === "true"
      ? "#bf2114"
      : props?.theme?.token?.colorPrimary} !important;
  color: #fff !important;
  box-shadow: ${(props) => props?.theme?.token?.boxShadowNone};
`;

export const LedgerSuffix = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) => (props?.ledgerBalance >= 0 ? "green" : "red")};
`;
