import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Select,
  Table,
  theme,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { SEGMENTS } from "../../../Helper/constant";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import {
  setBlockedScripts,
  setEditData,
} from "../../../store/slice/scriptSettingsRequestSlice";
import { useMutation } from "@tanstack/react-query";
import { useWatch } from "antd/es/form/Form";
import { useGetBarScript } from "../../../APICall/apiHooks";
import { EditableCell } from "./FooterView";
const _ = require("lodash");

const ModifiedWrapperView = (props, _ref) => {
  const { tab, parentForm, activeKey, previousChange } = props;
  const dispatch = useDispatch();
  const isEQUOrCRYPTO = ["NSE EQU", "CRYPTO"].includes(tab);
  //state
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [sort, setSort] = useState({ column: null, order: null });

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const scriptInfo = useSelector(
    (state) =>
      state?.userAccountSlice?.[tab]?.["scriptWiseSettingInfo"]?.selectedScript
  );
  const apidata = useSelector(
    (state) => state?.userAccountSlice?.[tab]?.brokerageDetails
  );

  const initalDelivery = apidata?.deliveryTotal;
  const initalIntraday = apidata?.intradayTotal;

  const watchIntraday = useWatch(`intradayTotal$${tab}`, parentForm);
  const watchDelivery = useWatch(`deliveryTotal$${tab}`, parentForm);

  const scripSettings = useSelector((state) => state.scriptSettingSlice?.[tab]);
  const parentScriptS = useSelector(
    (state) => state.userAccountSlice.parentValidation
  );
  const brokarageDetails = useSelector(
    (state) => state.userAccountSlice?.parentValidation?.brokarageDetails?.[tab]
  );
  const watchBrokerageType = useWatch(`brokerageType$${tab}`, parentForm);

  const userAccountType = useSelector(
    (state) => state.userAccountSlice.accountType
  );
  const isRent = parentScriptS?.accountDetails?.partnershipWith === "rent";

  const scriptSettingsRequest = useSelector(
    (state) => state.scriptSettingsRequestSlice?.[tab]
  );
  console.log("scriptSettingsRequest", scriptSettingsRequest);
  console.log("scripSettings", scripSettings);
  const staticBlocked = scriptSettingsRequest?.staticBlocked || [];

  const [extraRow, setExtraRow] = useState({
    key: "0",
    id: "All",
    block: false,
    scriptName: "All",
    // isExtra: true,
    open: "",
    delivery: watchDelivery || initalDelivery,
    intraday: watchIntraday || initalIntraday,
    lqv: null,
    min: null,
    max: null,
    total: null,
    operation: "operation",
  });

  useEffect(() => {
    const privousData = [];
    const onlyBlocked = scriptSettingsRequest?.blocked?.map((el) => ({
      scriptName: el,
    }));
    Object.keys(scripSettings).forEach((key) => {
      if (key !== "All" && scripSettings[key]?.scriptName)
        privousData.push(scripSettings[key]);
    });
    setData([...privousData, ...onlyBlocked]);
  }, [scriptSettingsRequest, scripSettings]);

  useEffect(() => {
    if (scriptInfo === true) {
      setData((pre) =>
        pre?.map((item) => {
          return { ...item, checked: true };
        })
      );
    } else {
      setData((pre) =>
        pre?.map((item) => {
          return { ...item, checked: false };
        })
      );
    }
  }, [scriptInfo]);

  useEffect(() => {
    setExtraRow((pre) => ({
      ...pre,
      delivery: watchDelivery,
      intraday: watchIntraday,
    }));
  }, [watchDelivery, watchIntraday]);

  const {
    data: marketArrayForAllCheck,
    isLoading: allCheckArrayLoading,
    isError,
    mutate: mutateAllArray,
  } = useMutation({
    mutationFn: async () => {
      let url = `${EndPoints.getTableScript}${SEGMENTS[tab]}`;
      if (searchValue) {
        url += `&search=${searchValue}`;
      }
      let response = await APICall("get", url);
      if (response?.status === 200) {
        let symbolName = response?.data?.data?.data?.map(
          (el) => el?.symbolName
        );
        setSelectedRow((pre) =>
          searchValue ? [...pre, ...symbolName] : ["All", ...symbolName]
        );
      } else {
        notification.error("message", response?.data?.message);
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  //function

  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...record,
    });

    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    if (key === "All") {
      let isTouched = form.isFieldsTouched();
      let temp = form.getFieldsValue();
      if (temp?.maxValue === false) {
        temp.maxValue = null;
      }
      if (temp?.minValue === false) {
        temp.minValue = null;
      }
      if (isTouched) {
        dispatch(
          setEditData({
            tab: tab,
            edited: [...scriptSettingsRequest?.edited, { ...temp }],
          })
        );
      }
      setExtraRow({ ...extraRow, ...temp });
      setEditingKey("");
    } else {
      try {
        const row = await form.validateFields();
        let filtered = scriptSettingsRequest?.edited.filter(
          (item) => item.scriptName !== row.scriptName
        );
        dispatch(
          setEditData({
            tab: tab,
            edited: [...filtered, row],
          })
        );
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setData(newData);

          setEditingKey("");
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey("");
        }
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      editable: false,
      width: "4%",
    },
    {
      title: "Block",
      dataIndex: "block",
      width: "4%",
      editable: false,
      render: (_, record) => {
        const isblocked = staticBlocked?.includes(record?.scriptName);
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              name={record.scriptName}
              onChange={async (e) => {
                let isChecked = e.target.checked;
                setSelectedRow((prevSelectedRow) =>
                  isChecked
                    ? [...prevSelectedRow, record.scriptName]
                    : prevSelectedRow.filter(
                        (item) => item !== record.scriptName && item !== "All"
                      )
                );
              }}
              checked={selectedRow.includes(record.scriptName)}
              disabled={record.id === "All" ? false : isblocked ? true : false}
            />
          </div>
        );
      },
    },
    {
      title: "Script Name",
      dataIndex: "scriptName",
      width: "9%",
      sorter: true,
      editable: false,
      render: (_, record) => {
        return (
          <div>
            <div>{record.scriptName}</div>
            {record.scriptName !== "" && record.scriptName !== "All" && (
              <div>Lot size : {record.lotSize}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Close Price",
      dataIndex: "open",
      editable: false,
      width: "9%",
      sorter: true,
    },
    {
      title: "Brokerage",
      dataIndex: "brokerage",
      editable: true,
      children: [
        {
          title: "Delivery",
          dataIndex: "delivery",
          inputType: "maskedNumber",
          editable: true,
          width: "9%",
          isValidation: true,
          render: (_, record) => {
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );
            const isblocked = staticBlocked?.includes(record?.scriptName);

            return (
              <Tooltip
                trigger={"hover"}
                title={`${
                  record?.scriptName !== "All" ? "Click To Start Editing" : ""
                }`}
              >
                <InputNumber
                  value={record?.delivery}
                  disabled={isblocked || newBlock}
                  onClick={() => {
                    record?.scriptName !== "All" && edit(record);
                  }}
                  readOnly={record?.scriptName === "All" && !isblocked}
                  addonAfter={watchBrokerageType === "percentage" ? "%" : "Fix"}
                />
              </Tooltip>
            );
          },
          validatorFunction: (a, b) => {
            if (b < (isRent ? 0 : brokarageDetails?.deliveryTotal) && b) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${
                    isRent ? "0" : brokarageDetails?.deliveryTotal
                  } ${
                    watchBrokerageType === "percentage" ? `and less than 1` : ""
                  } `
                )
              );
            } else if (watchBrokerageType === "percentage" && b >= 1 && b) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${brokarageDetails?.deliveryTotal} and less than 1`
                )
              );
            } else {
              return Promise.resolve();
            }
          },
        },
        {
          title: "Intraday",
          dataIndex: "intraday",
          inputType: "maskedNumber",
          editable: true,
          width: "9%",
          isValidation: true,
          render: (_, record) => {
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );
            const isblocked = staticBlocked?.includes(record?.scriptName);
            return (
              <Tooltip
                trigger={"hover"}
                title={`${
                  record?.scriptName !== "All" ? "Click To Start Editing" : ""
                }`}
              >
                <InputNumber
                  value={record?.intraday}
                  disabled={isblocked || newBlock}
                  onClick={() => {
                    record?.scriptName !== "All" && edit(record);
                  }}
                  readOnly={record?.scriptName === "All" && !isblocked}
                  addonAfter={watchBrokerageType === "percentage" ? "%" : "Fix"}
                />
              </Tooltip>
            );
          },
          validatorFunction: (a, b) => {
            if (b < (isRent ? 0 : brokarageDetails?.intradayTotal) && b) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${
                    isRent ? "0" : brokarageDetails?.intradayTotal
                  } ${
                    watchBrokerageType === "percentage" ? `and less than 1` : ""
                  } `
                )
              );
            } else if (watchBrokerageType === "percentage" && b >= 1 && b) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${brokarageDetails?.intradayTotal} and less than 1`
                )
              );
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    },
    {
      title: "L / Q / V",
      dataIndex: "type",
      inputType: "dropdown",
      width: "9%",
      editable: true,
      render: (_, record) => {
        let newBlock = scriptSettingsRequest?.blocked?.includes(
          record?.scriptName
        );
        const isblocked = staticBlocked?.includes(record?.scriptName);
        let defalutvalue = isEQUOrCRYPTO ? "quantity" : "lot";

        return (
          <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
            <Select
              style={{ minWidth: "100px" }}
              value={record?.type || defalutvalue}
              open={false}
              onClick={() => !isblocked && !newBlock && edit(record)}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Single Trade",
      dataIndex: "singleTrade",
      width: "9%",
      editable: true,

      children: [
        {
          title: "Min",
          dataIndex: "minValue",
          width: "9%",
          isValidation: false,
          editable: true,
          inputType: "number",
          render: (_, record) => {
            const isblocked = staticBlocked?.includes(record?.scriptName);
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );

            return (
              <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
                <InputNumber
                  onClick={() => edit(record)}
                  value={record?.minValue}
                  readOnly
                  disabled={isblocked || newBlock}
                />
              </Tooltip>
            );
          },
        },
        {
          title: "Max",
          dataIndex: "maxValue",
          width: "9%",
          isValidation: false,
          editable: true,
          inputType: "number",
          render: (_, record) => {
            const isblocked = staticBlocked?.includes(record?.scriptName);
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );
            return (
              <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
                <InputNumber
                  onClick={() => edit(record)}
                  value={record?.maxValue}
                  readOnly
                  disabled={isblocked || newBlock}
                />
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: "Total",
      dataIndex: "total",
      inputType: "number",
      width: "9%",
      isValidation: false,
      editable: true,
      render: (_, record) => {
        const isblocked = staticBlocked?.includes(record?.scriptName);
        let newBlock = scriptSettingsRequest?.blocked?.includes(
          record?.scriptName
        );
        return (
          <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
            <InputNumber
              onClick={() => edit(record)}
              value={record.total}
              readOnly
              disabled={isblocked || newBlock}
            />
          </Tooltip>
        );
      },
      // validatorFunction: (a, b) => {
      //   if (
      //     isRent
      //       ? b < 0
      //       : b > parentScriptS?.marginDetails?.[tab].delivery || b < 0
      //   ) {
      //     return Promise.reject(
      //       new Error(
      //         ` You can not give total less then  ${
      //           isRent ? "0" : parentScriptS?.marginDetails?.[tab].delivery
      //         }`
      //       )
      //     );
      //   } else {
      //     return Promise.resolve();
      //   }
      // },
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: "9%",
      // fixed: "right",
      editable: false,
      render: (_, record) => {
        const editable = isEditing(record);

        return (
          <div style={{ width: "100px" }}>
            {editable ? (
              <>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => save(record.id)}
                  icon={<SaveOutlined />}
                  style={{
                    marginRight: 8,
                  }}
                ></Button>
                {/* <Popconfirm
                    title="Sure to cancel?"
                    placement="topRight"
                    onConfirm={cancel}
                  >
                    <Button size="small" danger icon={<StopOutlined />}></Button>
                  </Popconfirm> */}
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  size="small"
                  disabled={
                    accountType !== "customer" ||
                    selectedRow.includes(record.scriptName) ||
                    editingKey !== "" ||
                    staticBlocked?.[tab]?.includes(`${record.scriptName}`)
                      ? true
                      : false
                  }
                  onClick={() => edit(record)}
                  icon={<EditOutlined />}
                  style={{ marginRight: 8 }}
                ></Button>
                <Button
                  title="Edit"
                  type="primary"
                  style={{ visibility: "hidden" }}
                  size="small"
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                  icon={<EditOutlined />}
                ></Button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const otherColumns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      editable: false,
      width: "4%",
    },
    {
      title: "Block",
      dataIndex: "block",
      width: "4%",
      editable: false,
      render: (_, record) => {
        const isblocked = staticBlocked?.includes(record?.scriptName);

        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              name={record.scriptName}
              onChange={(e) => {
                let isChecked = e.target.checked;
                if (isChecked) {
                  setSelectedRow([...selectedRow, record.scriptName]);
                } else {
                  setSelectedRow(
                    selectedRow.filter(
                      (item) => item !== record.scriptName && item !== "All"
                    )
                  );
                }
              }}
              checked={selectedRow.includes(record.scriptName)}
              disabled={record.id === "All" ? false : isblocked ? true : false}
            />
          </div>
        );
      },
    },
    {
      title: "Script Name",
      dataIndex: "scriptName",
      width: "9%",
      sorter: true,
      editable: false,
      render: (_, record) => {
        return (
          <div>
            <div>{record.scriptName}</div>
            {record.scriptName !== "All" && (
              <div>Lot size : {record.lotSize}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Open Price",
      dataIndex: "open",
      sorter: true,
      editable: false,
      width: "9%",
    },
  ];

  useEffect(() => {
    // set inital block from ban script api
    if (scriptSettingsRequest.blocked) {
      let blocked = scriptSettingsRequest.blocked;
      setSelectedRow(blocked);
    }
  }, []);

  useEffect(() => {
    if (editingKey === "") {
      form.resetFields();
    }
  }, [editingKey]);

  function appendDataByKey(targetArray, sourceArray, key) {
    // Create a map from the source array for quick lookups
    const sourceMap = new Map(sourceArray.map((item) => [item[key], item]));

    // Append data from the source map to the target array
    return targetArray.map((item) => {
      const sourceItem = sourceMap.get(item[key]);
      if (sourceItem) {
        return { ...item, ...sourceItem };
      }
      return item;
    });
  }

  useEffect(() => {
    let main = document
      .querySelector(".table-body")
      ?.querySelector(".ant-table-content");
    let header = document
      .querySelector(".table-header")
      ?.querySelector(".ant-table-content");
    if (main && header) {
      main.addEventListener("scroll", () => {
        header.scrollLeft = main.scrollLeft;
      });
      header.addEventListener("scroll", () => {
        main.scrollLeft = header.scrollLeft;
      });
    }
  }, [editingKey]);

  useEffect(() => {
    if (scriptSettingsRequest?.edited?.length > 0 && data && !loading) {
      let tempData = appendDataByKey(
        data,
        scriptSettingsRequest?.edited,
        "scriptName"
      );
      setData([...tempData]);
    }
  }, [scriptSettingsRequest]);

  useEffect(() => {
    dispatch(setBlockedScripts({ tab: tab, blocked: selectedRow }));
  }, [selectedRow]);

  const dataSource = [...data];
  const cols = userAccountType === "customer" ? columns : otherColumns;
  const mergedColumns = cols.map((col) => {
    if (col.dataIndex !== "operation") {
      if (!col.children) {
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            inputType: col.inputType || "text",
            validatorFunction: col.validatorFunction,
            isValidation: col.isValidation,
            brokerageType: brokarageDetails?.brokerageType,
            watchBrokerageType: watchBrokerageType,
            tab: tab,
          }),
        };
      } else {
        return {
          ...col,
          children: col.children.map((child) => ({
            ...child,
            onCell: (record) => ({
              record,
              editable: child.editable,
              dataIndex: child.dataIndex,
              title: child.title,
              editing: isEditing(record),
              inputType: child.inputType || "number",
              validatorFunction: child.validatorFunction,
              isValidation: child.isValidation,
              brokerageType: brokarageDetails?.brokerageType,
              watchBrokerageType: watchBrokerageType,
              tab: tab,
            }),
          })),
        };
      }
    } else {
      return { ...col };
    }
  });
  const handleSearch = (e) => {
    functionDebounce(e);
  };

  const functionDebounce = _.debounce((e) => {
    if (page !== 1) {
      setPage(1);
    }
    setSearchValue(e.target.value);
    // appendData(page, e.target.value);
  }, 300);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <h4>
          {previousChange
            ? "Previous Edit/Block Table"
            : "New Edit/Block Table"}
        </h4>
        <Input
          style={{ width: "300px" }}
          onChange={handleSearch}
          placeholder="Search anything here"
          prefix={<SearchOutlined />}
          // value={searchValue}
        />
      </div>
      <Form form={form} component={false}>
        <Table
          className="users-custom-table table-header empty-table-tbody"
          columns={mergedColumns}
          scroll={{ x: 1200 }}
          sortDirections={["ascend", "descend", "ascend"]}
          virtual={editingKey ? false : true}
          onChange={(pagination, filters, sorter, extra) => {
            setSort({
              column: sorter?.field,
              order: sorter?.order === "ascend" ? "asc" : "desc",
            });
            setPage(1);
          }}
          dataSource={[]}
          rowClassName="editable-row"
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />

        <Table
          showHeader={false}
          scroll={{ x: 1200, scrollToFirstRowOnChange: false }}
          className="users-custom-table table-body"
          virtual={editingKey ? false : true}
          loading={allCheckArrayLoading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={{
            total: 10 * totalData,
            current: page,
            onChange: (e) => {
              setPage(e);
            },
            showSizeChanger: false,
          }}
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
        />
      </Form>
    </>
  );
};

export default ModifiedWrapperView;
