import React, { useRef, useState } from "react";
import { Button, Col, Row, Skeleton, Tooltip, notification } from "antd";
import ThemeDropDown from "../../../components/ThemeDropDown";
import { allOption, labelObj } from "../../../Helper/constant";
import ThemeInput from "../../../components/ThemeInput";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import {
  AccountTypeRadioButton,
  ActionButtonsDiv,
  EditButtonHold,
  EntriesDiv,
  FilterText,
  StyTable,
  StyledCol,
  StyledModal,
  StyledSpace,
  TextDiv,
  TypeSpace,
  UplineTag,
  UserInfoDiv,
  UserText,
  WrraperDiv,
  accountOption,
  option,
} from "./style";
import UPlineComponant from "./Components/UPlineComponant";
import ExpirySettingModal from "./Components/ExpirySettingModal";
import ResetButton from "./Components/ResetButton";
import StatusButton from "./Components/StatusButton";
import { valanBillDate } from "../../../components/CommanFuction/CommanFunction";
import {
  EditUserButton,
  StyledButton,
  TextLeftDiv,
  UserListButtonexport,
} from "../../../styles/commonStyle";
import useGetQueryParams from "../../../hooks/useGetQueryParams";
import UserFilterComponant from "../../../components/UserFilterComponant/UserFilterComponant";
import ThemeCard from "../../../components/ThemeCard";
import { ActiveDot } from "../../TradingTrade/style";
import { setUserClearFilter } from "../../../store/slice/globleSlice";
import EditUserButtonComponent from "./Components/EditUserButton";

const initalStatus = { flage: false, item: "" };
const AccountPage = () => {
  // state
  const [searchValue, setSearchValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [isExpiryModal, setIsExpiryModal] = useState({
    userId: null,
    flag: false,
  });
  const [totalQty, setTotalQty] = useState(0);
  const [showStatus, setShowStatus] = useState(initalStatus);
  const [pressTimer, setPressTimer] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const valueRef = useRef({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { params, setSearchParams, handleDeleteWithAdd } = useGetQueryParams();

  //redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const isRent = userDetail?.partnershipWith === "rent";
  const showAccounts =
    (isRent && userDetail?.accountType !== "dealer") ||
    userDetail?.accountType === "owner";

  // api call
  const getUsers = async () => {
    const page = params?.page || 1;
    const limit = params?.limit || 10;
    let url = `${EndPoints.getAllUser}?page=${page}&limit=${limit}`;
    let addUrl = ["type", "role", "userId", "search"];
    addUrl.forEach((el) => {
      if (params[el]) {
        url += `&${el}=${params[el]}`;
      }
    });

    let res = await APICall("get", url);
    setTotalQty(res?.data?.data?.pagination?.totalItems);
    return res?.data?.data;
  };

  const { isLoading, data, error, isFetching, refetch } = useQuery({
    queryKey: ["usersDetails", params],
    queryFn: getUsers,
    refetchOnMount: true,
    // keepPreviousData: true,
  });

  if (error) notification.error({ message: error.message });

  // function
  const EditUserDetails = (id) => {
    if (params?.type !== "rent") {
      navigate(`/users/edit-account/${id}`);
      // window.open(`/users/edit-account/${id}`, "_blank");
    } else {
      navigate(`/users/edit-rent-account/${id}`);
      // window.open(`/users/edit-rent-account/${id}`, "_blank");
    }
  };

  const handleChange = (value) => {
    if (value) {
      if (value[0] === "all") {
        handleDeleteWithAdd(["role"], null);
      } else {
        setSearchParams({ ...params, role: value[0], page: 1 });
      }
    }
  };

  const userLedger = (item) => {
    navigate(`/user-ledger/${item?.userId}`);
  };

  const handleExpiryModal = (userId) => {
    setIsExpiryModal({
      userId: userId,
      flag: true,
    });
  };

  const handleCancelExpiry = () => {
    setIsExpiryModal({
      userId: null,
      flag: false,
    });
  };

  useDebounce(
    () => {
      if (searchValue) {
        setSearchParams({ ...params, search: searchValue, page: 1 });
      } else {
        handleDeleteWithAdd(["search"], { page: 1 });
      }
    },
    400,
    [searchValue]
  );

  const handleScriptSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const onChangeRadio = (e) => {
    const { value } = e.target;
    if (value === "all") {
      handleDeleteWithAdd(["type"], null);
    } else {
      setSearchParams({ ...params, type: value, page: 1 });
    }
  };

  const handlePageChage = (value) => {
    setSearchParams({ ...params, page: value });
  };

  const ChangePageSize = (value) => {
    if (value) {
      setSearchParams({ ...params, limit: value?.[0], page: 1 });
    }
  };

  const handleShow = (item) => {
    setShowStatus({ flage: true, item: item });
  };
  const handleFind = () => {
    const userId = valueRef.current?.userId;
    if (userId) {
      setSearchParams({ ...params, userId: userId, page: 1 });
    }
  };

  const handleReset = () => {
    dispatch(setUserClearFilter(true));
    handleDeleteWithAdd(["userId"], { page: 1 });
    valueRef.current = {};
  };

  const handleStart = () => {
    const timer = setTimeout(() => {
      setApiCalled(true);
    }, 2000);

    setPressTimer(timer);
    setApiCalled(false); // Reset the flag when starting a new press
  };

  const handleEnd = (id) => {
    if (pressTimer) {
      clearTimeout(pressTimer);
      setPressTimer(null);

      if (!apiCalled) {
        EditUserDetails(id);
      }
    }
  };

  // column
  const columns = [
    {
      title: "SR.NO.",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "3rem",
      render: (text, record, index) => {
        return !isLoading && <span>{record?.index}</span>;
      },
    },
    isLoading
      ? {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: "16rem",
        }
      : {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          align: "center",
          width: "16rem",
          render: (imageUrl, item) => {
            return (
              !isLoading && (
                <UserInfoDiv>
                  <TextDiv>
                    <UserText>{item.accountName}</UserText>
                    <GetUserStatus id={item?._id} />
                  </TextDiv>
                  <TypeSpace size={[0, "small"]} wrap>
                    <UplineTag color="magenta" bordered={false}>
                      {labelObj[item.accountType]}
                    </UplineTag>
                  </TypeSpace>
                </UserInfoDiv>
              )
            );
          },
        },
    {
      title: "USER ID",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      width: "5rem",
      render: (text, record, index) => {
        return !isLoading && <div>{record?.userId}</div>;
      },
    },
    {
      title: "UPLINE",
      dataIndex: "upline",
      key: "upline",
      align: "center",
      width: "15rem",
      render: (text, record, index) => {
        return (
          !isLoading && (
            <TextLeftDiv>
              <UPlineComponant index={index} record={record} />
            </TextLeftDiv>
          )
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "35rem",
      render: (imageUrl, item) => {
        let isSquareUp = item?.isOnlyPositionSquareup;
        return (
          !isLoading && (
            <>
              {userDetail.accountType !== "broker" ? (
                <ActionButtonsDiv>
                  {/* <Tooltip placement="bottomLeft" title="Edit">
                    <EditUserButton
                      className="regularUserTableBtn"
                      // onClick={() => EditUserDetails(item.userId)}
                      onMouseDown={handleStart} // Desktop mouse down
                      onMouseUp={() => handleEnd(item.userId)} // Desktop mouse up
                      onMouseLeave={() => handleEnd(item.userId)} // Desktop edge case: mouse leaves the button
                      onTouchStart={handleStart} // Mobile touch start
                      onTouchEnd={() => handleEnd(item.userId)} // Mobile touch end
                    >
                      <div className="edit-button-text">E</div>
                      <EditButtonHold isSquareUp={isSquareUp}></EditButtonHold>
                    </EditUserButton>
                  </Tooltip> */}
                  <EditUserButtonComponent item={item} />
                  <Tooltip placement="bottomLeft" title="Ledger">
                    <Button
                      className="redsUserTableBtn"
                      onClick={() => userLedger(item)}
                    >
                      L
                    </Button>
                  </Tooltip>
                  <ResetButton item={item} />
                  <Tooltip placement="bottomLeft" title={"Status"}>
                    <UserListButtonexport
                      className="regularUserTableBtn"
                      onClick={() => handleShow(item)}
                      // onClick={setShowStatus({ flage: true, item: item })}
                    >
                      S
                    </UserListButtonexport>
                  </Tooltip>
                  {item?.accountType === "masterAdmin" && (
                    <Tooltip placement="bottomLeft" title="Expairy Settings">
                      <UserListButtonexport
                        className="regularUserTableBtn"
                        onClick={() => handleExpiryModal(item?.userId)}
                      >
                        Ex
                      </UserListButtonexport>
                    </Tooltip>
                  )}

                  {/* <Tooltip placement="bottomLeft" title="User Profile">
                    <UserListButtonexport
                      className="regularUserTableBtn"
                      onClick={() =>
                        navigate(`/users/user-profile/${item?.userId}`)
                      }
                    >
                      <UserOutlined />
                    </UserListButtonexport>
                  </Tooltip> */}
                </ActionButtonsDiv>
              ) : (
                <div>
                  <Tooltip placement="bottomLeft" title="Ledger">
                    <Button
                      className="redsUserTableBtn"
                      onClick={() => userLedger(item)}
                    >
                      L
                    </Button>
                  </Tooltip>
                </div>
              )}
            </>
          )
        );
      },
    },
    {
      title: "LOGIN TIME",
      dataIndex: "loginTime",
      key: "loginTime",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        const time = valanBillDate(record?.loginTime, true);
        return !isLoading && <div>{time}</div>;
      },
    },
    {
      title: "LAST ACTIVITY",
      dataIndex: "lastActivity",
      key: "lastActivity",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        const time = valanBillDate(record?.lastActivity, true);
        return !isLoading && time;
      },
    },
    {
      title: "LOGIN IP",
      dataIndex: "ipAddress",
      key: "ipAddress",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        return !isLoading && <TextLeftDiv>{record?.ipAddress}</TextLeftDiv>;
      },
    },
  ];

  const SkeletonLoading = () => {
    return (
      <div className="account-table-skeleton">
        {new Array(4).fill({}).map((el, index) => {
          return (
            <Skeleton.Input
              key={index}
              active={true}
              style={{ width: `${(index + 1) * 15}rem`, height: "1.6rem" }}
            ></Skeleton.Input>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {showFilter && (
        <ThemeCard>
          <Row align={"middle"} gutter={[10, 15]}>
            {showAccounts && (
              <StyledCol xs={24} sm={12} md={8} lg={8} xl={4}>
                <AccountTypeRadioButton
                  options={accountOption}
                  onChange={onChangeRadio}
                  value={params?.type || "all"}
                  optionType="button"
                  buttonStyle="solid"
                />
              </StyledCol>
            )}
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <ThemeDropDown
                name={"accountType"}
                title={"Account Role"}
                allowClear={false}
                placeholder="Select"
                $value={params?.role || "all"}
                onChange={(value) => handleChange(value)}
                showSearch={true}
                options={allOption?.filter(
                  (item) => item.authorityLevel > userDetail.authorityLevel
                )}
                width={"16rem"}
                required
              />
            </Col>
            <UserFilterComponant valueRef={valueRef} />
            <Col xs={24} sm={12} md={8} lg={6} xl={3}>
              <FilterText onClick={() => setShowFilter(!showFilter)}>
                {"- Less Filters"}
              </FilterText>
            </Col>

            <StyledCol xs={24} sm={12} md={8} lg={3} xl={2} left={"auto"}>
              <StyledButton type="primary" onClick={handleFind}>
                Search
              </StyledButton>
            </StyledCol>
            <StyledCol xs={24} sm={12} md={8} lg={3} xl={2}>
              <StyledButton type="primary" onClick={handleReset}>
                Clear Search
              </StyledButton>
            </StyledCol>
          </Row>
        </ThemeCard>
      )}

      {!showFilter && (
        <Row align={"middle"} justify={"start"} gutter={[10, 15]}>
          <Col xs={24} sm={12} md={8} lg={6} xl={3}>
            <ThemeDropDown
              name={"accountType"}
              title={"Account Role"}
              placeholder="Select"
              allowClear={false}
              $value={params?.role || "all"}
              onChange={(value) => handleChange(value)}
              showSearch={true}
              options={allOption?.filter(
                (item) => item.authorityLevel > userDetail.authorityLevel
              )}
              width={"16rem"}
              required
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={3}>
            <FilterText onClick={() => setShowFilter(!showFilter)}>
              {"+ More Filters"}
            </FilterText>
          </Col>
          {showAccounts && (
            <StyledCol
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={4}
              style={{ marginLeft: "auto" }}
            >
              <AccountTypeRadioButton
                options={accountOption}
                onChange={onChangeRadio}
                value={params?.type || "all"}
                optionType="button"
                buttonStyle="solid"
              />
            </StyledCol>
          )}
        </Row>
      )}

      {/* <Row justify={"space-between"} style={{ marginTop: "1rem" }}>
        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
          <ThemeDropDown
            name={"accountType"}
            title={"Account Type"}
            placeholder="Select"
            $value={params?.role || "all"}
            onChange={(value) => handleChange(value)}
            showSearch={true}
            options={allOption?.filter(
              (item) => item.authorityLevel > userDetail.authorityLevel
            )}
            width={"16rem"}
            required
          />
        </Col>
        {showAccounts && (
          <Col style={{ marginTop: "1.6rem" }}>
            <AccountTypeRadioButton
              options={accountOption}
              onChange={onChangeRadio}
              value={params?.type || "default"}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
        )}
      </Row> */}
      <WrraperDiv>
        <StyledSpace>
          <div style={{ display: "flex", alignItems: "center" }}>
            <EntriesDiv>Show Entries</EntriesDiv>
            <ThemeDropDown
              placeholder="All"
              options={option}
              value={params?.limit || 10}
              onChange={ChangePageSize}
              width={"8rem"}
              required
            />
          </div>

          <div className="userSearchInput" style={{ width: "100%" }}>
            <ThemeInput
              width={"100%"}
              required
              inputProps={{
                placeholder: "Search anything here",
                prefix: <SearchOutlined />,
                onChange: handleScriptSearch,
              }}
            />
          </div>
        </StyledSpace>
        <div style={{ marginTop: "1rem" }}>
          <StyTable
            className="userTable"
            columns={columns}
            dataSource={data?.userData}
            loading={{
              spinning: isLoading,
              indicator: <SkeletonLoading />,
            }}
            pagination={{
              position: ["bottomRight"],
              current: params?.page || 1,
              defaultCurrent: 1,
              total: totalQty,
              showSizeChanger: false,
              pageSize: data?.pagination?.itemsPerPage || 10,
              onChange: handlePageChage,
            }}
            rowKey="_id"
            scroll={{
              x: 1300,
            }}
          />
        </div>
      </WrraperDiv>
      {isExpiryModal.flag && (
        <StyledModal
          open={isExpiryModal.flag}
          // onOk={handleOk}
          onCancel={handleCancelExpiry}
        >
          <ExpirySettingModal userId={isExpiryModal?.userId} />
        </StyledModal>
      )}
      {showStatus?.flage && (
        <StatusButton
          item={showStatus?.item}
          setShowStatus={setShowStatus}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default AccountPage;

const GetUserStatus = ({ id }) => {
  const status = useSelector(
    (state) => state?.webSocketSlice?.liveUserInfo?.[id]
  );

  return <ActiveDot status={status?.status} />;
};
