import { valanBillDate } from "../CommanFuction/CommanFunction";
import { formatToINR } from "../FormateToINR/formatToINR";
import {
  BrokerSharingPdfColumn,
  FooterRow,
  LedgerColumns,
  Summaycolumns,
  TableTd,
  styles,
} from "./style";
import { Document, Page, Text, View, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export const convertToCSV = (data, columns) => {
  const header = columns.map((col) => col.title).join(",");
  const rows = data.map((row, index) => {
    return columns
      .map((col) => {
        let value;
        if (col.dataIndex === "srNo" && row.userId !== "Total") {
          value = index + 1; // Handle SR NO. separately as it is index based
        } else if (col.dataIndex === "date") {
          value = valanBillDate(row?.date, true);
        } else {
          value = row[col.dataIndex];
          if (col.dataIndex === "userId") {
            value =
              row.userId === "Total"
                ? `${row.userId}`
                : `${row.userId} (${row.AccountName})`;
          } else if (col.dataIndex === "valan") {
            value = value?.valanName || "";
          } else {
            value = value !== null && value !== undefined ? value : "";
          }
        }
        return value;
      })
      .join(",");
  });
  return [header, ...rows].join("\n");
};
export function convertToCSVBroker(data, columns) {
  // Flatten column definitions
  const flattenColumns = (cols) => {
    return cols.reduce((acc, col) => {
      if (col.children) {
        return [...acc, ...col.children];
      }
      return [...acc, col];
    }, []);
  };

  const flatColumns = flattenColumns(columns);

  // Generate headers
  const headers = flatColumns.map((col) => col.title).join(",");

  // Generate rows
  const csvRows = data.map((item) =>
    flatColumns
      .map((col) => {
        // Use the render function if available, otherwise use the dataIndex
        const value = col.render
          ? col.render(item[col.dataIndex], item)
          : item[col.dataIndex];

        // Escape commas and quotes
        const escapedValue =
          value !== undefined && value !== null
            ? `"${String(value).replace(/"/g, '""')}"`
            : '""';

        return escapedValue;
      })
      .join(",")
  );

  return [headers, ...csvRows].join("\n");
}
export const calculateTotals = (data, filtertype) => {
  if (filtertype === "summary") {
    const totals = {};
    Summaycolumns?.forEach?.((col) => {
      if (
        col.dataIndex !== "srNo" &&
        col.dataIndex !== "userId" &&
        col.dataIndex !== "valan"
      ) {
        totals[col.dataIndex] = data?.reduce?.((acc, row) => {
          const value = row[col.dataIndex];
          return (
            acc +
            (value !== null && value !== undefined ? parseFloat(value) : 0)
          );
        }, 0);
      }
    });
    return totals;
  } else if (filtertype === "broker") {
    const totals = {};
    BrokerSharingPdfColumn?.forEach?.((col) => {
      if (
        col.dataIndex !== "srNo" &&
        col.dataIndex !== "userId" &&
        col.dataIndex !== "valan"
      ) {
        totals[col.dataIndex] = data?.reduce?.((acc, row) => {
          const value = row[col.dataIndex];
          return (
            acc +
            (value !== null && value !== undefined ? parseFloat(value) : 0)
          );
        }, 0);
      }
    });
    return totals;
  } else {
    return null;
  }
};
export const totalValan = (data, name) => {
  if (data) {
    const total = data?.reduce?.((acc, item) => {
      let roundValue = item?.[name]?.toFixed(0);
      return (acc += Number(roundValue) || 0);
    }, 0);
    return formatToINR(total || 0);
  }
};
export const SubTotalData = (data, number, subName) => {
  if (data) {
    const total = data?.reduce?.((acc, item) => {
      let roundValue = item?.brokerData?.[number]?.[subName];
      return (acc += Number(roundValue) || 0);
    }, 0);
    return formatToINR(total || 0);
  }
};
export const summaryFooter = (data) => {
  const filteredData = data?.map((el) => ({
    ...el,
    downlinem2m: el?.downlinem2m * -1,
    uplinem2m: el?.uplinem2m * -1,
    selfm2m: el?.selfm2m * -1,
  }));
  return (
    <FooterRow>
      <td colSpan="2">Total :</td>
      <td></td>
      <td>{totalValan(filteredData, "totalNetAmount")}</td>
      <td></td>
      <td>{totalValan(filteredData, "withoutBrokerage")}</td>
      <td>{totalValan(filteredData, "brokerage")}</td>
      <td>{totalValan(filteredData, "downlinem2m")}</td>
      <td>{totalValan(filteredData, "uplinem2m")}</td>
      <td>{totalValan(filteredData, "selfm2m")}</td>
    </FooterRow>
  );
};
export const BrokerageFooter = (filteredData) => (
  <FooterRow>
    <td colSpan="2">Total :</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td>{totalValan(filteredData, "totalBrokerage")}</td>
    <td>{totalValan(filteredData, "myBrokerage")}</td>
    <td>{totalValan(filteredData, "uplineBrokerage")}</td>
    <td>{totalValan(filteredData, "downlineBrokerage")}</td>
    {/* <td></td>
    <td></td>
    <td></td> */}
  </FooterRow>
);
export const BrokerFooter = (filteredData, broker) => (
  <FooterRow>
    <td colSpan="2">Total :</td>
    <td></td>
    <td>{totalValan(filteredData, "totalValanBill")}</td>
    <td>{totalValan(filteredData, "brokerage")}</td>
    <td></td>
    <TableTd>{SubTotalData(filteredData, 0, "percentage")}</TableTd>
    <TableTd>{SubTotalData(filteredData, 0, "brokerBrokerage")}</TableTd>
    {broker && <td></td>}
    {broker && <TableTd>{SubTotalData(filteredData, 1, "percentage")}</TableTd>}
    {broker && (
      <TableTd>{SubTotalData(filteredData, 1, "brokerBrokerage")}</TableTd>
    )}
    {broker && <td></td>}
    {broker && <TableTd>{SubTotalData(filteredData, 2, "percentage")}</TableTd>}
    {broker && (
      <TableTd>{SubTotalData(filteredData, 2, "brokerBrokerage")}</TableTd>
    )}
  </FooterRow>
);
export const checkEntry = (value) => {
  if (
    value == "pnl" ||
    value === "brokerage" ||
    value === "trade" ||
    value === "tradeLog" ||
    value === "rejectionLog" ||
    value === "m2m" ||
    value === "editLogs" ||
    value === "ipAddress"
  ) {
    return true;
  } else {
    return false;
  }
};
export const filterConfigs = {
  trade: (item, value) => {
    const customer = item?.customer?.[0];
    const tradeBy = item?.tradeBy?.[0];
    return (
      customer?.accountName?.toLowerCase().includes(value) ||
      customer?.userId?.toString().includes(value) ||
      item?.deviceType?.toLowerCase()?.toString().includes(value) ||
      item?.marketName?.toLowerCase()?.toString().includes(value) ||
      item?.symbolName?.toLowerCase()?.toString().includes(value) ||
      item?.title?.toLowerCase()?.toString().includes(value) ||
      item?.tradePattern?.toLowerCase()?.toString().includes(value) ||
      item?.tradeStatus?.toLowerCase()?.toString().includes(value) ||
      tradeBy?.userId?.toString().includes(value) ||
      tradeBy?.accountName?.toLowerCase()?.toString().includes(value)
    );
  },
  summary: (item, value) => {
    return (
      item.user?.accountName?.toLowerCase().includes(value) ||
      item.user?.userId?.toString().includes(value)
    );
  },
  tradeLog: (item, value) => {
    const client = item?.client;
    return (
      client?.accountName?.toLowerCase().includes(value) ||
      client?.userId?.toString().includes(value) ||
      item?.symbolName?.toLowerCase()?.toString().includes(value) ||
      item?.title?.toLowerCase()?.toString().includes(value) ||
      item?.tradePattern?.toLowerCase()?.toString().includes(value)
    );
  },
  rejectionLog: (item, value) => {
    const client = item?.client;
    const message = item?.message;
    return (
      client?.accountName?.toLowerCase().includes(value) ||
      client?.userId?.toString().includes(value) ||
      item?.symbolName?.toLowerCase()?.toString().includes(value) ||
      item?.title?.toLowerCase()?.toString().includes(value) ||
      item?.tradePattern?.toLowerCase()?.toString().includes(value) ||
      message?.code?.toString().includes(value)
    );
  },
  m2m: (item, value) => {
    const data = item?.data;
    return (
      data?.userName?.toLowerCase()?.toString()?.includes(value) ||
      data?.message?.toLowerCase()?.toString()?.includes(value) ||
      item?.clientId?.toString()?.includes(value)
    );
  },
  brokerage: (item, value) => {
    return (
      item?.clientName?.toLowerCase()?.toString()?.includes(value) ||
      item?.client?.toString()?.includes(value) ||
      item?.symbolName?.toLowerCase()?.toString()?.includes(value) ||
      item?.title?.toLowerCase()?.toString()?.includes(value) ||
      item?.tradeType?.toLowerCase()?.toString()?.includes(value)
    );
  },
  pnl: (item, value) => {
    return (
      item?.userId?.toString()?.includes(value) ||
      item?.accountName?.toLowerCase()?.toString()?.includes(value)
    );
  },
  blockScript: (item, value) => {
    return item?.Script?.toLowerCase()?.toString()?.includes(value);
  },
};
export const generatePDF = async (dataWithTotals, column, title) => {
  const pdfData = await pdf(
    <Document>
      <Page size="A2" style={styles.page}>
        {/* Render your table rows here */}
        <View style={styles.text}>
          <Text>{title}</Text>
        </View>
        <View>
          <View style={styles.scriptHeader}>
            {column.map((col, index) => (
              <Text style={styles.tableCell} key={index}>
                {col.title}
              </Text>
            ))}
          </View>
          {dataWithTotals?.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              {column.map((col, colIndex) => (
                <Text style={styles.tableCell} key={colIndex}>
                  {col.render
                    ? col.render(item[col.dataIndex], item, index)
                    : item[col.dataIndex]}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  ).toBlob();

  const pdfUrl = URL.createObjectURL(pdfData);
  const downloadLink = document.createElement("a");
  downloadLink.href = pdfUrl;
  downloadLink.download = `${title}.pdf`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
const BrokerSharingPDF = ({ data, oneBroker }) => {
  let array = oneBroker ? [0] : [0, 1, 2];

  // Calculate totals for footer
  const totals = data.reduce(
    (acc, item) => {
      return {
        totalValanBill: (acc.totalValanBill || 0) + (item.totalValanBill || 0),
        brokerage: (acc.brokerage || 0) + (item.brokerage || 0),
        brokerTotals: array.map((brokerIndex) => ({
          brokerBrokerage:
            Number(acc.brokerTotals?.[brokerIndex]?.brokerBrokerage || 0) +
            Number(item.brokerData?.[brokerIndex]?.brokerBrokerage || 0),
          percentage:
            Number(acc.brokerTotals?.[brokerIndex]?.percentage || 0) +
            Number(item.brokerData?.[brokerIndex]?.percentage || 0),
        })),
      };
    },
    { brokerTotals: [] }
  );
  return (
    <Document>
      <Page size="A2" style={styles.page}>
        <Text style={styles.text}>Broker Sharing Report</Text>

        {/* Main Header Row */}
        <View style={[styles.headerRow]}>
          <Text style={[styles.tableCell, { width: "40", color: "white" }]}>
            SR NO.
          </Text>
          <Text style={[styles.tableCell, { width: "120", color: "white" }]}>
            USER INFO
          </Text>
          <Text style={[styles.tableCell, { width: "100", color: "white" }]}>
            VALAN
          </Text>
          <Text style={[styles.tableCell, { width: "120", color: "white" }]}>
            TOTAL VALAN BILL
          </Text>
          <Text style={[styles.tableCell, { width: "100", color: "white" }]}>
            BROKERAGE
          </Text>

          {/* Broker Group Headers */}
          {array.map((brokerNum) => (
            <View
              key={brokerNum}
              style={[styles.brokerGroup, { width: "240" }]}
            >
              <Text style={styles.brokerHeader}>{`Broker ${
                array?.length === 1 ? "" : brokerNum
              }`}</Text>
              <View style={styles.brokerSubHeader}>
                <Text style={[styles.brokerSubHeaderCell, { width: "80" }]}>
                  Name
                </Text>
                <Text style={[styles.brokerSubHeaderCell, { width: "80" }]}>
                  %(Sharing)
                </Text>
                <Text style={[styles.brokerSubHeaderCell, { width: "80" }]}>
                  BR(Sharing)
                </Text>
              </View>
            </View>
          ))}
        </View>

        {/* Table Data */}
        {data.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={[styles.tableCell, { width: "40" }]}>{index + 1}</Text>
            <Text style={[styles.tableCell, { width: "120" }]}>
              {`${item.userId} (${item.accountName})`}
            </Text>
            <Text style={[styles.tableCell, { width: "100" }]}>
              {item?.valanId?.valanName || "-"}
            </Text>
            <Text style={[styles.tableCell, { width: "120" }]}>
              {formatToINR(item.totalValanBill) ?? "-"}
            </Text>
            <Text style={[styles.tableCell, { width: "100" }]}>
              {formatToINR(item.brokerage) ?? "-"}
            </Text>

            {/* Broker Data Cells */}
            {array.map((brokerIndex) => {
              const broker = item?.brokerData?.[brokerIndex];
              return (
                <View
                  key={brokerIndex}
                  style={[styles.brokerGroup, { width: "240" }]}
                >
                  <View style={styles.brokerSubHeader}>
                    <Text style={[styles.tableCell, { width: "80" }]}>
                      {broker?.accountName || "-"}
                    </Text>
                    <Text style={[styles.tableCell, { width: "80" }]}>
                      {formatToINR(broker?.percentage)}
                    </Text>
                    <Text style={[styles.tableCell, { width: "80" }]}>
                      {formatToINR(broker?.brokerBrokerage)}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        ))}

        {/* Footer Row */}
        <View style={[styles.tableRow, styles.footerRow]}>
          <Text style={[styles.tableCell, { width: "40" }]}></Text>
          <Text
            style={[styles.tableCell, { width: "120", fontWeight: "bold" }]}
          >
            Total
          </Text>
          <Text style={[styles.tableCell, { width: "100" }]}></Text>

          <Text
            style={[styles.tableCell, { width: "120", fontWeight: "bold" }]}
          >
            {formatToINR(totals.totalValanBill)}
          </Text>
          <Text
            style={[styles.tableCell, { width: "100", fontWeight: "bold" }]}
          >
            {formatToINR(totals.brokerage)}
          </Text>

          {/* Broker Totals */}
          {array.map((brokerIndex) => (
            <View
              key={brokerIndex}
              style={[styles.brokerGroup, { width: "240" }]}
            >
              <View style={styles.brokerSubHeader}>
                <Text style={[styles.tableCell, { width: "80" }]}>-</Text>
                <Text style={[styles.tableCell, { width: "80" }]}>
                  {formatToINR(totals.brokerTotals[brokerIndex]?.percentage)}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "80", fontWeight: "bold" },
                  ]}
                >
                  {formatToINR(
                    totals.brokerTotals[brokerIndex]?.brokerBrokerage
                  )}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
export const generateBrokerPDF = async (dataWithTotals, oneBroker) => {
  try {
    const pdfData = await pdf(
      <BrokerSharingPDF data={dataWithTotals} oneBroker={oneBroker} />
    ).toBlob();
    const pdfUrl = URL.createObjectURL(pdfData);
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = "broker_sharing_summary.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
export const handleExportCSV = ({
  filteredData,
  totals,
  filtertype,
  params,
}) => {
  if (filtertype === "summary") {
    const dataWithTotals = [...filteredData, { ...totals, userId: "Total" }];
    const csv = convertToCSV(dataWithTotals, Summaycolumns);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${filtertype}_Report.csv`);
  } else if (filtertype === "ledger") {
    const ledgerData = filteredData.map((item) => {
      return { ...item, AccountName: item?.accountName };
    });
    const csv = convertToCSV(ledgerData, LedgerColumns);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${filtertype}_Report.csv`);
  } else if (filtertype === "broker") {
    const dataWithTotals = [...filteredData, { ...totals, userId: "Total" }];
    const brokerData = dataWithTotals?.map((item) => {
      return {
        ...item,
        AccountName: item?.accountName,
        valan: item?.valanId,
        // Broker 1 Data
        broker1Name: item?.brokerData?.[0]?.accountName || "-",
        broker1Pr: item?.brokerData?.[0]?.percentage || "-",
        broker1Br: item?.brokerData?.[0]?.brokerBrokerage || "-",

        // Broker 2 Data
        broker2Name: item?.brokerData?.[1]?.accountName || "-",
        broker2Pr: item?.brokerData?.[1]?.percentage || "-",
        broker2Br: item?.brokerData?.[1]?.brokerBrokerage || "-",

        // Broker 3 Data
        broker3Name: item?.brokerData?.[2]?.accountName || "-",
        broker3Pr: item?.brokerData?.[2]?.percentage || "-",
        broker3Br: item?.brokerData?.[2]?.brokerBrokerage || "-",
      };
    });
    const modifiyedColumn = BrokerSharingPdfColumn?.filter(
      (el, index) => index < 8
    );
    const csv = convertToCSV(
      brokerData,
      params?.brokerId ? modifiyedColumn : BrokerSharingPdfColumn
    );
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${filtertype}_Report.csv`);
  }
};
