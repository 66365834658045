import { useQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EndPoints from "../../APICall/EndPoints";
import { SEGMENTS } from "../../Helper/constant";
import APICall from "../../APICall";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Pagination,
  Row,
  Skeleton,
  notification,
} from "antd";
import {
  StyledSummarySearchInput,
  TableTitleSearch,
} from "../../components/ThemeCardTable/style";
import ThemeInput from "../../components/ThemeInput";
import { SearchOutlined } from "@ant-design/icons";
import { useEditUserDetails } from "../../APICall/apiHooks";

const EditBlockScript = ({ tab, preBlocked, scriptRef, form }) => {
  //state
  const [pages, setPages] = useState({ page: 1, total: 1 });
  const [selectedScript, setSelectedScript] = useState([]);
  const [search, setSearch] = useState("");

  // const [form] = Form.useForm();

  // redux
  const userDetail = useSelector((state) => state.authSlice?.userDetail);
  const loginId = userDetail?.userId;
  const accountType = userDetail?.accountType;

  // custome hook for edit uer, use for edit block script
  const { editUserData, editUserLoading, editUserMutate } =
    useEditUserDetails(loginId);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 300),
    []
  );

  // api call
  const getScriptListBySegment = async () => {
    let url = `${EndPoints.getTableScript}${SEGMENTS[tab]}&page=${
      pages?.page
    }&limit=${50}`;

    if (search) {
      url += `&search=${search}`;
    }

    let response = await APICall("get", url);
    setPages({ ...pages, total: response?.data?.data?.totalCount });

    return response?.data?.data?.data;
  };

  const {
    isLoading: scriptLoading,
    data: scriptData,
    error,
  } = useQuery({
    queryKey: ["getScript", tab, pages?.page, search],
    queryFn: getScriptListBySegment,
  });

  if (error) notification.error({ message: error?.message });

  // life cycle
  useEffect(() => {
    if (scriptData?.length > 0) {
      const marketBlocked = preBlocked
        ?.filter((el) => el?.marketName == tab)
        ?.map((el) => el?.Script);
      let blockedScripts = scriptData?.filter(
        (el) => !marketBlocked?.includes(el?.symbolName)
      );
      setSelectedScript(blockedScripts);
    }
  }, [preBlocked, scriptData]);

  // function
  const handleSearch = (e) => {
    debouncedSearch(e?.target?.value);
  };

  const handleSubmitForm = (value) => {
    // console.log("handleSubmitForm", value);
    const scriptList = scriptRef.current;

    const payload = Object.entries(scriptList).reduce(
      (acc, [script, isBlock]) => {
        const [symbol, marketName] = script.split("$");

        if (!marketName) return acc;
        if (!isBlock) return acc;
        const index = acc.findIndex((el) => el.scriptName === marketName);
        if (index === -1)
          acc.push({
            scriptName: marketName,
            blockScript: [
              { symbol: symbol, status: "block", blockedBy: "self" },
            ],
          });
        else
          acc[index].blockScript.push({
            symbol: symbol,
            status: "block",
            blockedBy: "self",
          });

        return acc;
      },
      []
    );

    // preBlocked?.forEach((el) => {
    //   const { Script, marketName } = el;
    //   const findMarketIndex = payload?.findIndex(
    //     (item) => item?.scriptName === marketName
    //   );
    //   if (findMarketIndex !== -1) {
    //     payload[findMarketIndex].blockScript.push({
    //       symbol: Script,
    //       status: "block",
    //       blockedBy: "self",
    //     });
    //   }
    // });

    // console.log("payload", payload);
    if (payload?.length === 0)
      return notification.error({
        message: "Please select any script to block",
      });
    editUserMutate({ accountType, marketAccess: payload });
    form.resetFields();
    scriptRef.current = {};
  };

  const handleFailedForm = (value) => {
    console.log("handleFailedForm", value);
  };

  const handleFromChange = (e) => {
    const { name, checked } = e.target;
    scriptRef.current[name] = checked;
  };

  return (
    <>
      <TableTitleSearch xxl={6} xl={6} lg={6} md={8} sm={12} xs={24}>
        <StyledSummarySearchInput>
          <ThemeInput
            wrapperwidth={"100%"}
            inputProps={{
              placeholder: "Search anything here",
              prefix: <SearchOutlined />,
              style: { width: "100%" },
              onChange: handleSearch,
            }}
          />
        </StyledSummarySearchInput>
      </TableTitleSearch>

      <Form
        onChange={handleFromChange}
        onFinish={handleSubmitForm}
        onFinishFailed={handleFailedForm}
        form={form}
      >
        <Row gutter={[10, 10]} style={{ marginTop: "1rem" }}>
          {scriptLoading ? (
            <Skeleton />
          ) : (
            selectedScript?.map((el) => {
              return (
                <Col key={el?.symbolName} xs={12} sm={6} md={4} lg={4} xl={3}>
                  <Form.Item
                    name={`${el?.symbolName}$${tab}`}
                    valuePropName="checked"
                  >
                    <Checkbox name={`${el?.symbolName}$${tab}`}>
                      {el?.symbolName}
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            })
          )}
        </Row>

        <Row justify={"end"}>
          <Pagination
            current={pages?.page}
            total={pages.total}
            pageSize={50}
            onChange={(page) => setPages({ ...pages, page: page })}
            showSizeChanger={false}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={editUserLoading}>
              Block Script
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default React.memo(EditBlockScript);
