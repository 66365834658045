import { Space, Typography, Radio, Form, Tooltip } from "antd";
import { styled } from "styled-components";

const StyledTheme = {
  Space: styled(Space)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .ant-radio-wrapper {
      font-size: 1.1rem;
      min-width: 5rem;
    }
    @media screen and (max-width: 750px) {
      width: 100%;
    }
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      width: ${(props) => props.$width};
      color: ${(props) => props.theme.token.colorTextLabel};
      font-family: ${(props) => props.theme.token.fontFamily};
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0rem;
      line-height: 1.125rem;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }

      @media screen and (max-width: 500px) {
        width: 13rem;
      }
      @media screen and (max-width: 400px) {
        width: 10rem;
      }
    }
  `,
};

const AccountDetailsRadioView = (props) => {
  //functions
  return (
    <StyledTheme.Space size={"large"}>
      <StyledTheme.Title $width={props?.$width || "20rem"}>
        {props?.title}
      </StyledTheme.Title>
      <Tooltip title={props?.toolTipTitle} placement="top">
        <Form.Item {...props?.formProps}>
          <Radio.Group {...props}>
            {props?.options?.length > 0 ? (
              props?.options?.map((option) => (
                <Radio key={option?.value} value={option?.value}>
                  {option?.label}
                </Radio>
              ))
            ) : (
              <>
                <Radio value={true}>yes</Radio>
                <Radio value={false}>no</Radio>
              </>
            )}
          </Radio.Group>
        </Form.Item>
      </Tooltip>
    </StyledTheme.Space>
  );
};

export default AccountDetailsRadioView;
