import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Col, Skeleton } from "antd";
import ThemeDropDown from "../ThemeDropDown";
import ThemeInput from "../ThemeInput";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setEntry } from "../../store/slice/dataFilterSlice";
import {
  FileTypeButton,
  StyledDiv,
  StyledRow,
  StyledSummarySearchInput,
  Summaycolumns,
  itemOption,
  TableTitleCol,
  TableTitleSearch,
  StyTable,
  ThemeTableWrapper,
  LedgerColumns,
  ColumnSettingIcon,
} from "./style";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import useDebounce from "../../hooks/3-useDebounce/useDebounce";
import {
  BrokerFooter,
  BrokerageFooter,
  calculateTotals,
  checkEntry,
  filterConfigs,
  generateBrokerPDF,
  generatePDF,
  handleExportCSV,
  summaryFooter,
} from "./themeTableCommanFunction";
import TableFilterModal from "../TableFilterModal/TableFilterModal";

function ThemeCardTable({
  buttonType,
  buttonTitle,
  column,
  data,
  isLoading,
  filtertype,
  footer,
  isFilter,
  checkList,
  columnFilter,
  setSelectedRow,
  selectedRow,
  multipleSelect,
}) {
  // state
  const [filteredData, setFilteredData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const totals = useMemo(
    () => calculateTotals(filteredData, filtertype),
    [filteredData]
  );
  const { params, setSearchParams, handleDeleteWithAdd } = useGetQueryParams();

  //redux
  const entries = useSelector(
    (state) => state?.dataFilterSlice?.entriesPerPage
  );

  // life cycle
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // function
  const handleTradeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const config = filterConfigs?.[filtertype];
    if (!config) {
      // console.warn(`Invalid filter type: ${filtertype}`);
      return;
    }

    if (value === "") {
      setFilteredData(data);
      return;
    }

    const filterFunction = filterConfigs[filtertype];
    if (filterFunction) {
      const searchedData = data?.filter((item) => filterFunction(item, value));
      setFilteredData(searchedData);
    }
  };

  // useDebounce(
  //   () => {
  //     if (search) {
  //       setSearchParams({ ...params, search: search });
  //     } else if (params?.search) {
  //       handleDeleteWithAdd("search", null);
  //     }
  //   },
  //   300,
  //   [search]
  // );

  const handleChange = (value) => {
    if (value) {
      let isSet = checkEntry(filtertype);
      if (isSet) {
        setSearchParams({ ...params, page: 1, limit: value?.[0] });
      }
      dispatch(setEntry(value[0]));
    }
  };

  const handleDownload = (title, key) => {
    if (title === "csv") {
      handleExportCSV({ filteredData, totals, filtertype, params });
    } else if (title === "pdf") {
      const dataWithTotals = [...filteredData, { ...totals, userId: "Total" }];
      const oneBroker = column?.some((item) => item?.title === "Broker");
      filtertype === "summary"
        ? generatePDF(dataWithTotals, Summaycolumns, "Summary Report")
        : filtertype === "ledger"
        ? generatePDF(filteredData, LedgerColumns, "Ledger Report")
        : generateBrokerPDF(filteredData, oneBroker, "Broker Sharing Report");
    } else {
      let objToAdd =
        title === "all"
          ? null
          : title === "limit" || title === "intradaylimit"
          ? { [key]: title, tradeStatus: "executed" }
          : { [key]: title };
      let removeKey =
        title === "all"
          ? ["title", "tradeStatus"]
          : key === "title"
          ? "tradeStatus"
          : "title";
      handleDeleteWithAdd(removeKey, objToAdd);
    }
  };

  const SkeletonLoading = useCallback(() => {
    return (
      <div className="account-table-skeleton">
        {new Array(3).fill({}).map((el, index) => {
          return (
            <Skeleton.Input
              key={index}
              active={true}
              style={{ width: `${(index + 1) * 15}rem`, height: "1.6rem" }}
            ></Skeleton.Input>
          );
        })}
      </div>
    );
  }, []);

  const rowClassName = (record) => {
    if (filtertype === "tradeLog") {
      switch (record?.logType) {
        case "DELETE":
          return "delete-row";
        case "UPDATE":
          return "update-row";
        case "CREATE":
          return "create-row";
        default:
          return "";
      }
    }
  };

  const handleFilter = (pagination, filters, sorter) => {
    if (filters?.action?.[0] && filtertype === "tradeLog") {
      handleDeleteWithAdd(null, { logType: filters?.action?.[0], page: 1 });
    } else {
      handleDeleteWithAdd(["logType"], { page: 1 });
    }
  };

  const handleSummary = useCallback(() => {
    if (!footer || isLoading) return null;

    switch (filtertype) {
      case "summary":
        return summaryFooter(filteredData);

      case "brokerage":
        return BrokerageFooter(filteredData);

      case "broker":
        return BrokerFooter(filteredData, params?.brokerId ? false : true);

      default:
        return null;
    }
  }, [footer, isLoading, filteredData, filtertype, params?.brokerId]);

  const rowSelection = {
    selectedRowKeys: selectedRow,
    columnTitle: <span style={{ display: "none" }} />,
    onChange: (selectedRowKeys, selectedRows, key) => {
      if (key?.type !== "all") {
        setSelectedRow(selectedRowKeys);
      }
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === "Disabled User",
    //   name: record.name,
    // }),
  };

  return (
    <ThemeTableWrapper>
      <StyledRow gutter={[10, 10]} justify={"space-between"}>
        <TableTitleCol xxl={4} xl={4} lg={6} md={8} sm={12} xs={12}>
          <StyledDiv>Show Entries</StyledDiv>
          <ThemeDropDown
            placeholder="Select"
            width={"150%"}
            required
            allowClear={false}
            value={checkEntry(filtertype) ? params?.limit : entries}
            onChange={handleChange}
            options={itemOption}
          />
        </TableTitleCol>

        {buttonType &&
          buttonTitle.map((title, index) => (
            <Col xxl={2} xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
              <FileTypeButton
                key={index}
                type="primary"
                onClick={() => handleDownload(title?.value, title.key)}
                style={{
                  backgroundColor:
                    params?.title === title?.value ||
                    params?.tradeStatus === title?.value ||
                    (title?.value === "all" &&
                      !params?.title &&
                      !params?.tradeStatus)
                      ? "#728485"
                      : "",
                }}
              >
                {title?.label}
              </FileTypeButton>
            </Col>
          ))}

        <TableTitleSearch
          xxl={4}
          xl={4}
          lg={6}
          md={8}
          sm={12}
          xs={12}
          filtertype={filtertype}
        >
          <StyledSummarySearchInput>
            {isFilter && (
              <ColumnSettingIcon onClick={() => setShowFilter(true)} />
            )}
            <ThemeInput
              wrapperwidth={"100%"}
              inputProps={{
                placeholder: "Search anything here",
                prefix: <SearchOutlined />,
                style: { width: "100%" },
                onChange: handleTradeSearch,
              }}
            />
          </StyledSummarySearchInput>
        </TableTitleSearch>
      </StyledRow>
      <div style={{ marginTop: "1rem" }}>
        <StyTable
          // style={{ minHeight: "40rem" }}
          className="userTable"
          rowKey={filtertype === "editLogs" ? "" : "_id"}
          columns={column}
          dataSource={filteredData}
          pagination={false}
          loading={{
            spinning: isLoading ? true : false,
            indicator: <SkeletonLoading />,
          }}
          sticky
          scroll={{ x: "max-content" }}
          summary={handleSummary}
          rowClassName={rowClassName}
          onChange={handleFilter}
          {...(multipleSelect ? { rowSelection } : {})}
        />
      </div>
      {showFilter && (
        <TableFilterModal
          column={columnFilter}
          setShowFilter={setShowFilter}
          checkList={checkList}
          filtertype={filtertype}
        />
      )}
    </ThemeTableWrapper>
  );
}

export default ThemeCardTable;
