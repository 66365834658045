import { markets } from "../../../Helper/constant";
const modifyInitialValuesObject = (brokarageValidationDetails) => {
  let resultObj = {};

  function modifyObject(obj, prefix) {
    let newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[`${prefix}$${key}`] = obj[key];
      }
    }
    return newObj;
  }

  const appendBrokers = (data) => {
    return data?.map((el) => ({ ...el, brokerId: [el?.brokerId] }));
  };
  const appendMarketName = (name, obj) => {
    let temp = {};
    for (const key in obj) {
      resultObj[`${key}$${name}`] = obj[key];
    }
    return temp;
  };
  let brokerageArray = [];

  for (let i = 0; i < markets.length; i++) {
    resultObj[`additionalSharing$${markets[i]}`] = appendBrokers(
      brokarageValidationDetails[markets[i]]?.additionalSharing
    );
    resultObj[`isOnlyPositionSquareup$${markets[i]}`] =
      brokarageValidationDetails[markets[i]]?.isOnlyPositionSquareup;

    appendMarketName(
      markets[i],
      brokarageValidationDetails[markets[i]]?.brokerageDetails
    );
    appendMarketName(
      markets[i],
      brokarageValidationDetails[markets[i]]?.margin
    );
    appendMarketName(
      markets[i],
      brokarageValidationDetails[markets[i]]?.scriptWiseSettingInfo
    );
  }
  resultObj = {
    ...resultObj,
    [`additionalSharing`]: brokerageArray,
  };

  let preparedObj = {
    ...resultObj,
    ...brokarageValidationDetails.basicInfo,
    ...brokarageValidationDetails.accountDetails,
    ...modifyObject(
      brokarageValidationDetails.accountDetails.M2MAlert,
      "M2MAlert"
    ),
    ...modifyObject(
      brokarageValidationDetails.accountDetails.applyAutoSquareup,
      "applyAutoSquareup"
    ),
    ...modifyObject(
      brokarageValidationDetails.accountDetails.autoStopNewPosition,
      "autoStopNewPosition"
    ),
    freshLimitAllow: brokarageValidationDetails.accountDetails.freshLimitAllow,
    ...brokarageValidationDetails.accountDetails.additionalInfo,
  };

  return {
    ...preparedObj,
  };
};

export default modifyInitialValuesObject;
