// constants.js
import EventEmitter from "eventemitter3";
import { useLocation } from "react-router-dom";

export const Emitter = new EventEmitter();
export const Emitter_Event = {
  SEND: "doSend",
};

export const NAVBAR = {
  HEIGHT: "4rem",
  BACKGROUND_COLOR: "#313949",
};

const GetLocation = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const path = ["/dashboard", "/market", "/report", "/settings/notification"];
  const flage = path.includes(pathname);

  return flage;
};

// const flage1 = GetLocation();
// console.log("flage", flage1);

// let flage = true;
export const HEADER_HEIGHT = "5rem";
export const MAIN_CONTENT_HEIGHT1 = () => {
  const flage1 = GetLocation();
  // console.log("flage", flage1);
  return flage1;
  // return flage1
  //   ? `calc(100vh - ${NAVBAR.HEIGHT} - ${HEADER_HEIGHT} - 5rem)`
  //   : `calc(100vh - ${NAVBAR.HEIGHT} - ${HEADER_HEIGHT})`;
};
// const ans = MAIN_CONTENT_HEIGHT1();
// console.log("ans", ans);
let flage = false;
export const MAIN_CONTENT_HEIGHT = flage
  ? `calc(100vh - ${NAVBAR.HEIGHT} - ${HEADER_HEIGHT} - 5rem)`
  : `calc(95vh - ${NAVBAR.HEIGHT} - ${HEADER_HEIGHT})`;

export const VALIDATION = {
  REQUIRED: {
    required: true,
    message: "Required",
  },
};
export const SEGMENTS = {
  "NSE FUT": "nsefut",
  "NSE OPT": "nseop",
  "NSE EQU": "nseeq",
  MCX: "mcxfut",
  "MCX OPT": "mcxop",
  FOREX: "forex",
  COMEX: "comex",
  GLOBEX: "globex",
  CRYPTO: "crypto",
};
export const INDEX = {
  symbolid: 0,
  symbol: 1,
  series: 2,
  strike: 6,
  expiry: 7,

  touchline_Symbol: 0,
  touchline_SymbolId: 1,
  touchline_LastUpdateTime: 2,
  touchline_LTP: 3,
  touchline_TotalVolume: 6,
  touchline_Close: 10,
  touchline_Bid: 14,
  touchline_BidQty: 15,
  touchline_Ask: 16,
  touchline_AskQty: 17,

  trade_LTP: 2,
  trade_LTQ: 3, //Volume
  trade_Open: 6,
  trade_High: 7,
  trade_Low: 8,
  trade_Close: 9,
  trade_Bid: 15,
  trade_BidQty: 16,
  trade_Ask: 17,
  trade_AskQty: 18,

  bidask_TimeStamp: 1,
  bidask_Bid: 2,
  bidask_BidQty: 3,
  bidask_Ask: 4,
  bidask_AskQty: 5,
};
export const labelObj = {
  owner: "OW",
  masterAdmin: "MA",
  superAdmin: "SA",
  admin: "AD",
  master: "M",
  dealer: "DE",
  customer: "CU",
  broker: "BR",
};
export const accountObj = {
  admin: "allowNoOfAccountAdmin",
  broker: "allowNoOfAccountBroker",
  customer: "allowNoOfAccountCustomer",
  dealer: "allowNoOfAccountDealer",
  master: "allowNoOfAccountMaster",
  masterAdmin: "allowNoOfAccountMasterAdmin",
  superAdmin: "allowNoOfAccountSuperAdmin",
};

export const compareAccount = {
  allowNoOfAccountSuperAdmin: "SuperAdmin",
  allowNoOfAccountAdmin: "Admin",
  allowNoOfAccountMaster: "Master",
  allowNoOfAccountDealer: "Dealer",
  allowNoOfAccountBroker: "Broker",
  allowNoOfAccountCustomer: "Customer",
};

export const ConvertAccountName = {
  allowNoOfAccountSuperAdmin: "Super Admin",
  allowNoOfAccountAdmin: "Admin",
  allowNoOfAccountMaster: "Master",
  allowNoOfAccountDealer: "Dealer",
  allowNoOfAccountBroker: "Broker",
  allowNoOfAccountCustomer: "Customer",
};

export const UserFilterCompareObj = {
  ["Master Admin"]: "masterAdmin",
  ["Super Admin"]: "superAdmin",
  ["Admin"]: "admin",
  ["Master"]: "master",
  ["Dealer"]: "dealer",
  ["Broker"]: "broker",
  ["Customer"]: "customer",
};
export const compareObj = {
  isM2MAlert: ["M2MAlert$profit", "M2MAlert$loss"],
  isAutoStopNewPosition: [
    "autoStopNewPosition$profit",
    "autoStopNewPosition$loss",
  ],
  isApplyAutoSquareup: ["applyAutoSquareup$profit", "applyAutoSquareup$loss"],
  isNewPositionSquareupTime: ["newPositionSquareupTime"],
  isFreshLimitAllow: ["freshLimitAllow"],
};
export const sequence = [
  "NSE FUT",
  "NSE OPT",
  "NSE EQU",
  "MCX",
  "MCX OPT",
  "FOREX",
  "COMEX",
  "GLOBEX",
  "CRYPTO",
];
export const userSequence = [
  "Master Admin",
  "Super Admin",
  "Admin",
  "Master",
  "Dealer",
  "Broker",
  "Customer",
];

export const GlobleMarket = ["FOREX", "COMEX", "GLOBEX", "CRYPTO"];
export const ParmenantSymbol = ["256265", "265", "291849", "260105"];
export const tradeLogFilterOption = [
  { label: "Create", value: "CREATE", text: "Create" },
  { label: "Update", value: "UPDATE", text: "Update" },
  { label: "Delete", value: "DELETE", text: "Delete" },
];
export const defaultCustomerOptions = [
  { value: "small", label: "Small" },
  { value: "medium", label: "Medium" },
  { value: "large", label: "Large" },
];
export const defaultOptions = [{ value: "default", label: "Default Setting" }];

export const marketTag = {
  "NSE FUT": "NFO",
  "NSE OPT": "NOP",
  "NSE EQU": "NEQ",
  MCX: "MCX",
  "MCX OPT": "MOP",
  FOREX: "FRX",
  COMEX: "CMX",
  GLOBEX: "GLX",
  CRYPTO: "CRP",
};
export const markets = [
  "NSE FUT",
  "FOREX",
  "MCX OPT",
  "NSE EQU",
  "MCX",
  "NSE OPT",
  "COMEX",
  "GLOBEX",
  "CRYPTO",
];

export const additionalInfoNotView = [
  "newClientAdd",
  "additionalSharing",
  "broadcastMsg",
  "marginExamtion",
  "limitBetweenHighAndLow",
  "scriptWiseSettings",
  "allowLimitOrder",
  "allowQuantityTradingInfo ",
  "allowShortSellInfo",
];

export const deliveryValidation = [
  "deliveryTotal",
  "deliveryBuy",
  "deliverySell",
  "Delivery0",
  "Delivery1",
  "Delivery2",
];

export const InitialAdditionalInfo = {
  editTrade: "block",
  restoreAutoSquarUp: "block",
  acceptRejectedTrade: "block",
  ipAddressReport: "block",
  tvChannel: "block",
  clientDelete: "view",
  offlineTrade: "view",
  tradeDelete: "view",
  bfCfEdit: "view",
  ledgerDelete: "view",
  scriptBlocksList: "allow",
  valanDelete: "block",
  accountPointEntry: "allow",
  newClientAdd: "allow",
  additionalSharingInfo: "allow",
  scriptWiseSettings: "allow",
  broadcastMsg: "block",
  marginExamtion: "block",
  limitBetweenHighAndLow: "block",
  allowLimitOrder: "allow",
  allowQuantityTradingInfo: "block",
  allowShortSellInfo: "block",
};

export const intradayValidation = [
  "intradayTotal",
  "intradayBuy",
  "intradaySell",
  "Intraday0",
  "Intraday1",
  "Intraday2",
];

export const validationWithoutMarket = [
  "accountName",
  "accountPassword",
  "balance",
  "openingBalance",
  "allowNoOfAccountMasterAdmin",
  "allowNoOfAccountSuperAdmin",
  "allowNoOfAccountAdmin",
  "allowNoOfAccountMaster",
  "allowNoOfAccountBroker",
  "allowNoOfAccountDealer",
  "allowNoOfAccountCustomer",
  "M2MProfit",
  "M2MLoss",
  "AutoStopNewPositionProfit",
  "AutoStopNewPositionLoss",
  "ApplyAutoSquareupProfilt",
  "ApplyAutoSquareupLoss",
  "freshLimitAllow",
];

const getNFOScript = (type = "FUT") => {
  let data = [
    {
      InstrumentType: `${type}STK`,
      value: "AARTIIND",
      label: "AARTIIND",
      Lot: 1000,
    },
    { InstrumentType: `${type}STK`, value: "ABB", label: "ABB", Lot: 125 },
    {
      InstrumentType: `${type}STK`,
      value: "ABBOTINDIA",
      label: "ABBOTINDIA",
      Lot: 40,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ABCAPITAL",
      label: "ABCAPITAL",
      Lot: 5400,
    },
    { InstrumentType: `${type}STK`, value: "ABFRL", label: "ABFRL", Lot: 2600 },
    { InstrumentType: `${type}STK`, value: "ACC", label: "ACC", Lot: 300 },
    {
      InstrumentType: `${type}STK`,
      value: "ADANIENT",
      label: "ADANIENT",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ADANIPORTS",
      label: "ADANIPORTS",
      Lot: 800,
    },
    { InstrumentType: `${type}STK`, value: "ALKEM", label: "ALKEM", Lot: 200 },
    {
      InstrumentType: `${type}STK`,
      value: "AMBUJACEM",
      label: "AMBUJACEM",
      Lot: 1800,
    },
    {
      InstrumentType: `${type}STK`,
      value: "APOLLOHOSP",
      label: "APOLLOHOSP",
      Lot: 125,
    },
    {
      InstrumentType: `${type}STK`,
      value: "APOLLOTYRE",
      label: "APOLLOTYRE",
      Lot: 1700,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ASHOKLEY",
      label: "ASHOKLEY",
      Lot: 5000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ASIANPAINT",
      label: "ASIANPAINT",
      Lot: 200,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ASTRAL",
      label: "ASTRAL",
      Lot: 367,
    },
    { InstrumentType: `${type}STK`, value: "ATUL", label: "ATUL", Lot: 75 },
    {
      InstrumentType: `${type}STK`,
      value: "AUBANK",
      label: "AUBANK",
      Lot: 1000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "AUROPHARMA",
      label: "AUROPHARMA",
      Lot: 1100,
    },
    {
      InstrumentType: `${type}STK`,
      value: "AXISBANK",
      label: "AXISBANK",
      Lot: 625,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BAJAJ-AUTO",
      label: "BAJAJ-AUTO",
      Lot: 125,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BAJAJFINSV",
      label: "BAJAJFINSV",
      Lot: 500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BAJFINANCE",
      label: "BAJFINANCE",
      Lot: 125,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BALKRISIND",
      label: "BALKRISIND",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BALRAMCHIN",
      label: "BALRAMCHIN",
      Lot: 1600,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BANDHANBNK",
      label: "BANDHANBNK",
      Lot: 2500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BANKBARODA",
      label: "BANKBARODA",
      Lot: 2925,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BANKNIFTY",
      label: "BANKNIFTY",
      Lot: 15,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BATAINDIA",
      label: "BATAINDIA",
      Lot: 375,
    },
    { InstrumentType: `${type}STK`, value: "BEL", label: "BEL", Lot: 5700 },
    {
      InstrumentType: `${type}STK`,
      value: "BERGEPAINT",
      label: "BERGEPAINT",
      Lot: 1320,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BHARATFORG",
      label: "BHARATFORG",
      Lot: 500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BHARTIARTL",
      label: "BHARTIARTL",
      Lot: 950,
    },
    { InstrumentType: `${type}STK`, value: "BHEL", label: "BHEL", Lot: 5250 },
    {
      InstrumentType: `${type}STK`,
      value: "BIOCON",
      label: "BIOCON",
      Lot: 2500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "BOSCHLTD",
      label: "BOSCHLTD",
      Lot: 50,
    },
    { InstrumentType: `${type}STK`, value: "BPCL", label: "BPCL", Lot: 1800 },
    {
      InstrumentType: `${type}STK`,
      value: "BRITANNIA",
      label: "BRITANNIA",
      Lot: 200,
    },
    { InstrumentType: `${type}STK`, value: "BSOFT", label: "BSOFT", Lot: 1000 },
    { InstrumentType: `${type}STK`, value: "CANBK", label: "CANBK", Lot: 2700 },
    {
      InstrumentType: `${type}STK`,
      value: "CANFINHOME",
      label: "CANFINHOME",
      Lot: 975,
    },
    {
      InstrumentType: `${type}STK`,
      value: "CHAMBLFERT",
      label: "CHAMBLFERT",
      Lot: 1900,
    },
    {
      InstrumentType: `${type}STK`,
      value: "CHOLAFIN",
      label: "CHOLAFIN",
      Lot: 625,
    },
    { InstrumentType: `${type}STK`, value: "CIPLA", label: "CIPLA", Lot: 650 },
    {
      InstrumentType: `${type}STK`,
      value: "COALINDIA",
      label: "COALINDIA",
      Lot: 2100,
    },
    {
      InstrumentType: `${type}STK`,
      value: "COFORGE",
      label: "COFORGE",
      Lot: 150,
    },
    {
      InstrumentType: `${type}STK`,
      value: "COLPAL",
      label: "COLPAL",
      Lot: 350,
    },
    {
      InstrumentType: `${type}STK`,
      value: "CONCOR",
      label: "CONCOR",
      Lot: 1000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "COROMANDEL",
      label: "COROMANDEL",
      Lot: 700,
    },
    {
      InstrumentType: `${type}STK`,
      value: "CROMPTON",
      label: "CROMPTON",
      Lot: 1800,
    },
    { InstrumentType: `${type}STK`, value: "CUB", label: "CUB", Lot: 5000 },
    {
      InstrumentType: `${type}STK`,
      value: "CUMMINSIND",
      label: "CUMMINSIND",
      Lot: 300,
    },
    { InstrumentType: `${type}STK`, value: "DABUR", label: "DABUR", Lot: 1250 },
    {
      InstrumentType: `${type}STK`,
      value: "DALBHARAT",
      label: "DALBHARAT",
      Lot: 250,
    },
    {
      InstrumentType: `${type}STK`,
      value: "DEEPAKNTR",
      label: "DEEPAKNTR",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "DELTACORP",
      label: "DELTACORP",
      Lot: 2800,
    },
    {
      InstrumentType: `${type}STK`,
      value: "DIVISLAB",
      label: "DIVISLAB",
      Lot: 200,
    },
    { InstrumentType: `${type}STK`, value: "DIXON", label: "DIXON", Lot: 100 },
    { InstrumentType: `${type}STK`, value: "DLF", label: "DLF", Lot: 1650 },
    {
      InstrumentType: `${type}STK`,
      value: "DRREDDY",
      label: "DRREDDY",
      Lot: 125,
    },
    {
      InstrumentType: `${type}STK`,
      value: "EICHERMOT",
      label: "EICHERMOT",
      Lot: 175,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ESCORTS",
      label: "ESCORTS",
      Lot: 275,
    },
    {
      InstrumentType: `${type}STK`,
      value: "EXIDEIND",
      label: "EXIDEIND",
      Lot: 3600,
    },
    {
      InstrumentType: `${type}STK`,
      value: "FEDERALBNK",
      label: "FEDERALBNK",
      Lot: 5000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "FINNIFTY",
      label: "FINNIFTY",
      Lot: 40,
    },
    { InstrumentType: `${type}STK`, value: "GAIL", label: "GAIL", Lot: 4575 },
    {
      InstrumentType: `${type}STK`,
      value: "GLENMARK",
      label: "GLENMARK",
      Lot: 725,
    },
    {
      InstrumentType: `${type}STK`,
      value: "GMRINFRA",
      label: "GMRINFRA",
      Lot: 11250,
    },
    { InstrumentType: `${type}STK`, value: "GNFC", label: "GNFC", Lot: 1300 },
    {
      InstrumentType: `${type}STK`,
      value: "GODREJCP",
      label: "GODREJCP",
      Lot: 500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "GODREJPROP",
      label: "GODREJPROP",
      Lot: 475,
    },
    {
      InstrumentType: `${type}STK`,
      value: "GRANULES",
      label: "GRANULES",
      Lot: 2000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "GRASIM",
      label: "GRASIM",
      Lot: 475,
    },
    {
      InstrumentType: `${type}STK`,
      value: "GUJGASLTD",
      label: "GUJGASLTD",
      Lot: 1250,
    },
    { InstrumentType: `${type}STK`, value: "HAL", label: "HAL", Lot: 300 },
    {
      InstrumentType: `${type}STK`,
      value: "HAVELLS",
      label: "HAVELLS",
      Lot: 500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HCLTECH",
      label: "HCLTECH",
      Lot: 700,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HDFCAMC",
      label: "HDFCAMC",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HDFCBANK",
      label: "HDFCBANK",
      Lot: 550,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HDFCLIFE",
      label: "HDFCLIFE",
      Lot: 1100,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HEROMOTOCO",
      label: "HEROMOTOCO",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HINDALCO",
      label: "HINDALCO",
      Lot: 1400,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HINDCOPPER",
      label: "HINDCOPPER",
      Lot: 5300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HINDPETRO",
      label: "HINDPETRO",
      Lot: 2700,
    },
    {
      InstrumentType: `${type}STK`,
      value: "HINDUNILVR",
      label: "HINDUNILVR",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "IBULHSGFIN",
      label: "IBULHSGFIN",
      Lot: 5100,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ICICIBANK",
      label: "ICICIBANK",
      Lot: 700,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ICICIGI",
      label: "ICICIGI",
      Lot: 500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "ICICIPRULI",
      label: "ICICIPRULI",
      Lot: 1500,
    },
    { InstrumentType: `${type}STK`, value: "IDEA", label: "IDEA", Lot: 80000 },
    {
      InstrumentType: `${type}STK`,
      value: "IDFCFIRSTB",
      label: "IDFCFIRSTB",
      Lot: 7500,
    },
    { InstrumentType: `${type}STK`, value: "IDFC", label: "IDFC", Lot: 5000 },
    { InstrumentType: `${type}STK`, value: "IEX", label: "IEX", Lot: 3750 },
    { InstrumentType: `${type}STK`, value: "IGL", label: "IGL", Lot: 1375 },
    {
      InstrumentType: `${type}STK`,
      value: "INDHOTEL",
      label: "INDHOTEL",
      Lot: 2000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "INDIACEM",
      label: "INDIACEM",
      Lot: 2900,
    },
    {
      InstrumentType: `${type}STK`,
      value: "INDIAMART",
      label: "INDIAMART",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "INDIGO",
      label: "INDIGO",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "INDUSINDBK",
      label: "INDUSINDBK",
      Lot: 500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "INDUSTOWER",
      label: "INDUSTOWER",
      Lot: 3400,
    },
    { InstrumentType: `${type}STK`, value: "INFY", label: "INFY", Lot: 400 },
    { InstrumentType: `${type}STK`, value: "IOC", label: "IOC", Lot: 9750 },
    {
      InstrumentType: `${type}STK`,
      value: "IPCALAB",
      label: "IPCALAB",
      Lot: 650,
    },
    { InstrumentType: `${type}STK`, value: "IRCTC", label: "IRCTC", Lot: 875 },
    { InstrumentType: `${type}STK`, value: "ITC", label: "ITC", Lot: 1600 },
    {
      InstrumentType: `${type}STK`,
      value: "JINDALSTEL",
      label: "JINDALSTEL",
      Lot: 1250,
    },
    {
      InstrumentType: `${type}STK`,
      value: "JKCEMENT",
      label: "JKCEMENT",
      Lot: 250,
    },
    {
      InstrumentType: `${type}STK`,
      value: "JSWSTEEL",
      label: "JSWSTEEL",
      Lot: 675,
    },
    {
      InstrumentType: `${type}STK`,
      value: "JUBLFOOD",
      label: "JUBLFOOD",
      Lot: 1250,
    },
    {
      InstrumentType: `${type}STK`,
      value: "KOTAKBANK",
      label: "KOTAKBANK",
      Lot: 400,
    },
    { InstrumentType: `${type}STK`, value: "L&TFH", label: "L&TFH", Lot: 4462 },
    {
      InstrumentType: `${type}STK`,
      value: "LALPATHLAB",
      label: "LALPATHLAB",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "LAURUSLABS",
      label: "LAURUSLABS",
      Lot: 1700,
    },
    {
      InstrumentType: `${type}STK`,
      value: "LICHSGFIN",
      label: "LICHSGFIN",
      Lot: 2000,
    },
    { InstrumentType: `${type}STK`, value: "LT", label: "LT", Lot: 300 },
    { InstrumentType: `${type}STK`, value: "LTIM", label: "LTIM", Lot: 150 },
    { InstrumentType: `${type}STK`, value: "LTTS", label: "LTTS", Lot: 200 },
    { InstrumentType: `${type}STK`, value: "LUPIN", label: "LUPIN", Lot: 850 },
    {
      InstrumentType: `${type}STK`,
      value: "M&MFIN",
      label: "M&MFIN",
      Lot: 2000,
    },
    { InstrumentType: `${type}STK`, value: "M&M", label: "M&M", Lot: 350 },
    {
      InstrumentType: `${type}STK`,
      value: "MANAPPURAM",
      label: "MANAPPURAM",
      Lot: 6000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "MARICO",
      label: "MARICO",
      Lot: 1200,
    },
    { InstrumentType: `${type}STK`, value: "MARUTI", label: "MARUTI", Lot: 50 },
    {
      InstrumentType: `${type}STK`,
      value: "MCDOWELL-N",
      label: "MCDOWELL-N",
      Lot: 700,
    },
    { InstrumentType: `${type}STK`, value: "MCX", label: "MCX", Lot: 400 },
    {
      InstrumentType: `${type}STK`,
      value: "METROPOLIS",
      label: "METROPOLIS",
      Lot: 400,
    },
    { InstrumentType: `${type}STK`, value: "MFSL", label: "MFSL", Lot: 800 },
    { InstrumentType: `${type}STK`, value: "MGL", label: "MGL", Lot: 800 },
    {
      InstrumentType: `${type}STK`,
      value: "MIDCPNIFTY",
      label: "MIDCPNIFTY",
      Lot: 75,
    },
    {
      InstrumentType: `${type}STK`,
      value: "MOTHERSON",
      label: "MOTHERSON",
      Lot: 7100,
    },
    {
      InstrumentType: `${type}STK`,
      value: "MPHASIS",
      label: "MPHASIS",
      Lot: 275,
    },
    { InstrumentType: `${type}STK`, value: "MRF", label: "MRF", Lot: 5 },
    {
      InstrumentType: `${type}STK`,
      value: "MUTHOOTFIN",
      label: "MUTHOOTFIN",
      Lot: 550,
    },
    {
      InstrumentType: `${type}STK`,
      value: "NATIONALUM",
      label: "NATIONALUM",
      Lot: 7500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "NAUKRI",
      label: "NAUKRI",
      Lot: 150,
    },
    {
      InstrumentType: `${type}STK`,
      value: "NAVINFLUOR",
      label: "NAVINFLUOR",
      Lot: 150,
    },
    {
      InstrumentType: `${type}STK`,
      value: "NESTLEIND",
      label: "NESTLEIND",
      Lot: 40,
    },
    { InstrumentType: `${type}STK`, value: "NIFTY", label: "NIFTY", Lot: 50 },
    { InstrumentType: `${type}STK`, value: "NMDC", label: "NMDC", Lot: 4500 },
    { InstrumentType: `${type}STK`, value: "NTPC", label: "NTPC", Lot: 3000 },
    {
      InstrumentType: `${type}STK`,
      value: "OBEROIRLTY",
      label: "OBEROIRLTY",
      Lot: 700,
    },
    { InstrumentType: `${type}STK`, value: "OFSS", label: "OFSS", Lot: 200 },
    { InstrumentType: `${type}STK`, value: "ONGC", label: "ONGC", Lot: 3850 },
    {
      InstrumentType: `${type}STK`,
      value: "PAGEIND",
      label: "PAGEIND",
      Lot: 15,
    },
    { InstrumentType: `${type}STK`, value: "PEL", label: "PEL", Lot: 750 },
    {
      InstrumentType: `${type}STK`,
      value: "PERSISTENT",
      label: "PERSISTENT",
      Lot: 175,
    },
    {
      InstrumentType: `${type}STK`,
      value: "PETRONET",
      label: "PETRONET",
      Lot: 3000,
    },
    { InstrumentType: `${type}STK`, value: "PFC", label: "PFC", Lot: 3875 },
    {
      InstrumentType: `${type}STK`,
      value: "PIDILITIND",
      label: "PIDILITIND",
      Lot: 250,
    },
    { InstrumentType: `${type}STK`, value: "PIIND", label: "PIIND", Lot: 250 },
    { InstrumentType: `${type}STK`, value: "PNB", label: "PNB", Lot: 8000 },
    {
      InstrumentType: `${type}STK`,
      value: "POLYCAB",
      label: "POLYCAB",
      Lot: 100,
    },
    {
      InstrumentType: `${type}STK`,
      value: "POWERGRID",
      label: "POWERGRID",
      Lot: 3600,
    },
    {
      InstrumentType: `${type}STK`,
      value: "PVRINOX",
      label: "PVRINOX",
      Lot: 407,
    },
    {
      InstrumentType: `${type}STK`,
      value: "RAMCOCEM",
      label: "RAMCOCEM",
      Lot: 850,
    },
    {
      InstrumentType: `${type}STK`,
      value: "RBLBANK",
      label: "RBLBANK",
      Lot: 2500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "RECLTD",
      label: "RECLTD",
      Lot: 2000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "RELIANCE",
      label: "RELIANCE",
      Lot: 250,
    },
    { InstrumentType: `${type}STK`, value: "SAIL", label: "SAIL", Lot: 8000 },
    {
      InstrumentType: `${type}STK`,
      value: "SBICARD",
      label: "SBICARD",
      Lot: 800,
    },
    {
      InstrumentType: `${type}STK`,
      value: "SBILIFE",
      label: "SBILIFE",
      Lot: 750,
    },
    { InstrumentType: `${type}STK`, value: "SBIN", label: "SBIN", Lot: 1500 },
    {
      InstrumentType: `${type}STK`,
      value: "SHREECEM",
      label: "SHREECEM",
      Lot: 25,
    },
    {
      InstrumentType: `${type}STK`,
      value: "SHRIRAMFIN",
      label: "SHRIRAMFIN",
      Lot: 300,
    },
    {
      InstrumentType: `${type}STK`,
      value: "SIEMENS",
      label: "SIEMENS",
      Lot: 275,
    },
    { InstrumentType: `${type}STK`, value: "SRF", label: "SRF", Lot: 375 },
    {
      InstrumentType: `${type}STK`,
      value: "SUNPHARMA",
      label: "SUNPHARMA",
      Lot: 700,
    },
    { InstrumentType: `${type}STK`, value: "SUNTV", label: "SUNTV", Lot: 1500 },
    {
      InstrumentType: `${type}STK`,
      value: "SYNGENE",
      label: "SYNGENE",
      Lot: 1000,
    },
    {
      InstrumentType: `${type}STK`,
      value: "TATACHEM",
      label: "TATACHEM",
      Lot: 550,
    },
    {
      InstrumentType: `${type}STK`,
      value: "TATACOMM",
      label: "TATACOMM",
      Lot: 500,
    },
    {
      InstrumentType: `${type}STK`,
      value: "TATACONSUM",
      label: "TATACONSUM",
      Lot: 900,
    },
    {
      InstrumentType: `${type}STK`,
      value: "TATAMOTORS",
      label: "TATAMOTORS",
      Lot: 1425,
    },
    {
      InstrumentType: `${type}STK`,
      value: "TATAPOWER",
      label: "TATAPOWER",
      Lot: 3375,
    },
    {
      InstrumentType: `${type}STK`,
      value: "TATASTEEL",
      label: "TATASTEEL",
      Lot: 5500,
    },
    { InstrumentType: `${type}STK`, value: "TCS", label: "TCS", Lot: 175 },
    { InstrumentType: `${type}STK`, value: "TECHM", label: "TECHM", Lot: 600 },
    { InstrumentType: `${type}STK`, value: "TITAN", label: "TITAN", Lot: 375 },
    {
      InstrumentType: `${type}STK`,
      value: "TORNTPHARM",
      label: "TORNTPHARM",
      Lot: 500,
    },
    { InstrumentType: `${type}STK`, value: "TRENT", label: "TRENT", Lot: 400 },
    {
      InstrumentType: `${type}STK`,
      value: "TVSMOTOR",
      label: "TVSMOTOR",
      Lot: 350,
    },
    { InstrumentType: `${type}STK`, value: "UBL", label: "UBL", Lot: 400 },
    {
      InstrumentType: `${type}STK`,
      value: "ULTRACEMCO",
      label: "ULTRACEMCO",
      Lot: 100,
    },
    { InstrumentType: `${type}STK`, value: "UPL", label: "UPL", Lot: 1300 },
    { InstrumentType: `${type}STK`, value: "VEDL", label: "VEDL", Lot: 2000 },
    {
      InstrumentType: `${type}STK`,
      value: "VOLTAS",
      label: "VOLTAS",
      Lot: 600,
    },
    { InstrumentType: `${type}STK`, value: "WIPRO", label: "WIPRO", Lot: 1500 },
    { InstrumentType: `${type}STK`, value: "ZEEL", label: "ZEEL", Lot: 3000 },
    {
      InstrumentType: `${type}STK`,
      value: "ZYDUSLIFE",
      label: "ZYDUSLIFE",
      Lot: 900,
    },
  ];

  return data;
};

export const options = {
  user: {
    tab_exchange: {
      "NSE FUT": "NFO",
      "NSE OPT": "NFO",
      "NSE EQU": "NSE",
      MCX: "MCX",
      "MCX OPT": "MCX",
      FOREX: "CDS",
      COMEX: "CMX",
    },
    accountType: [
      {
        label: "Owner",
        value: "owner",
        value2: "owner",
        authorityLevel: 0,
      },
      {
        label: "Master Admin",
        value: "masterAdmin",
        value2: "MasterAdmin",
        authorityLevel: 1,
      },
      {
        label: "Super Admin",
        value: "superAdmin",
        value2: "SuperAdmin",
        authorityLevel: 2,
      },
      {
        label: "Admin",
        value: "admin",
        value2: "Admin",
        authorityLevel: 3,
      },
      {
        label: "Master",
        value: "master",
        value2: "Master",
        authorityLevel: 4,
      },
      {
        label: "Dealer",
        value: "dealer",
        value2: "Dealer",
        authorityLevel: 5,
      },
      {
        label: "Customer",
        value: "customer",
        value2: "Customer",
        authorityLevel: 6,
      },
      {
        label: "Broker",
        value: "broker",
        value2: "Broker",
        authorityLevel: 7,
      },
    ],
    margin: {
      value: [
        {
          label: "Quantity",
          value: "Quantity",
        },
        {
          label: "Value",
          value: "value",
        },
      ],
      lqv: [
        {
          label: "Lot",
          value: "lot",
        },
        {
          label: "Quantity",
          value: "quantity",
        },
        {
          label: "Value",
          value: "value",
        },
      ],
      equ: [
        {
          label: "Quantity",
          value: "quantity",
        },
        {
          label: "Value",
          value: "value",
        },
      ],
    },
    market_access: {
      tab_data: [
        { key: "NSE FUT", label: "NSE FUT" },
        { key: "NSE OPT", label: "NSE OPTION" },
        { key: "NSE EQU", label: "NSE EQU" },
        { key: "MCX", label: "MCX" },
        { key: "MCX OPT", label: "MCX OPTION" },
        { key: "FOREX", label: "FOREX" },
        { key: "COMEX", label: "COMEX/GLOBEX" },
      ],
      tab_data_by_key: {
        "NSE FUT": "NSE FUT",
        "NSE OPT": "NSE OPTION",
        "NSE EQU": "NSE EQU",
        MCX: "MCX",
        "MCX OPT": "MCX OPTION",
        FOREX: "FOREX",
        COMEX: "COMEX/GLOBEX",
      },
    },
    additional_info: [
      {
        label: "Edit trade",
        value: "editTrade",
      },
      {
        label: "Restore autosquarUp",
        value: "restoreAutoSquarUp",
      },
      {
        label: "Accept rejected trade",
        value: "acceptRejectedTrade",
      },
      {
        label: "IP address report",
        value: "ipAddressReport",
      },
      {
        label: "Tv channel",
        value: "tvChannel",
      },
      {
        label: "User delete",
        value: "clientDelete",
      },
      {
        label: "Offline trade/ Offline close position",
        value: "offlineTrade",
      },
      {
        label: "Trade delete",
        value: "tradeDelete",
      },
      {
        label: "Bf/Cf edit",
        value: "bfCfEdit",
      },
      {
        label: "Ledger entry edit/delete",
        value: "ledgerDelete",
      },
      {
        label: "Valan delete",
        value: "valanDelete",
      },
      {
        label: "Account point entry",
        value: "accountPointEntry",
      },
      {
        label: "Script blocks list",
        value: "scriptBlocksList",
      },

      {
        label: "New Client add",
        value: "newClientAdd",
      },
      {
        label: "Additional Sharing",
        value: "additionalSharingInfo",
      },
      {
        label: "Script wise settings",
        value: "scriptWiseSettings",
      },
      {
        label: "Broadcast msg",
        value: "broadcastMsg",
      },
      {
        label: "Order between high and low",
        value: "limitBetweenHighAndLow",
      },
      {
        label: "Allow Limit Order",
        value: "allowLimitOrder",
      },
      {
        label: "Allow Quantity Trading",
        value: "allowQuantityTradingInfo",
      },
      {
        label: "Allow short sell only for option",
        value: "allowShortSellInfo",
      },
    ],
    Expiry: {
      MCX_OPTION: [
        {
          label: "Quotation Rate",
          value: "Quotation Rate",
        },
        { label: "Closing Rate", value: "Closing Rate" },
        {
          label: "Last Trade",
          value: "Last Trade",
        },
      ],
      NSE_OPTIONS: [
        { label: "INDEX", value: "index" },
        { label: "All", value: "all" },
      ],
      FOREX_OPTION: [
        {
          label: "Quotation Rate",
          value: "Quotation Rate",
        },
        { label: "Closing Rate", value: "Closing Rate" },
      ],
    },
  },
  marketWatch: {
    Market: [
      {
        Exchange: "NFO",
        InstrumentType: ["FUTIDX", "FUTSTK"],
        value: "NSE FUT",
        label: "NSE FUT",
      },
      {
        Exchange: "NFO",
        InstrumentType: ["OPTIDX", "OPTSTK"],
        value: "NSE OPT",
        label: "NSE OPT",
      },
      {
        Exchange: "NSE",
        InstrumentType: [],
        value: "NSE EQU",
        label: "NSE EQU",
      },
      {
        Exchange: "MCX",
        InstrumentType: ["FUTCOM"],
        value: "MCX",
        label: "MCX",
      },
      {
        Exchange: "MCX",
        InstrumentType: ["OPTFUT"],
        value: "MCX OPT",
        label: "MCX OPT",
      },
      {
        Exchange: "CDS",
        InstrumentType: ["FUTCUR"],
        value: "FOREX",
        label: "FOREX",
      },
      {
        Exchange: "COMEX",
        InstrumentType: [],
        value: "COMEX",
        label: "COMEX",
      },
      {
        Exchange: "GLOBEX",
        InstrumentType: [],
        value: "GLOBEX",
        label: "GLOBEX",
      },
    ],
    script_name: {
      "NSE FUT": getNFOScript("FUT"),
      "NSE OPT": getNFOScript("OPT"),
      "NSE EQU": [
        {
          value: "NIFTY",
          label: "NIFTY",
        },

        {
          value: "AARTIIND",
          label: "AARTIIND",
        },

        {
          value: "ADANIENT",
          label: "ADANIENT",
        },
        {
          value: "ADANIPORTS",
          label: "ADANIPORTS",
        },
        {
          value: "ICICIGI",
          label: "ICICIGI",
        },
        {
          value: "INDIAMART",
          label: "INDIAMART",
        },
        {
          value: "INDIGO",
          label: "INDIGO",
        },
        {
          value: "RELIANCE",
          label: "RELIANCE",
        },
        {
          value: "TATACOMM",
          label: "TATACOMM",
        },

        {
          value: "TATAMOTORS",
          label: "TATAMOTORS",
        },
        {
          value: "TATAPOWER",
          label: "TATAPOWER",
        },
        {
          value: "TATASTEEL",
          label: "TATASTEEL",
        },
        { value: "TCS", label: "TCS" },
        {
          value: "TITAN",
          label: "TITAN",
        },
        {
          value: "TORNTPHARM",
          label: "TORNTPHARM",
        },
        {
          value: "ZYDUSLIFE",
          label: "ZYDUSLIFE",
        },
      ],
      MCX: [
        { value: "ALUMINI", label: "ALUMINI", InstrumentType: "FUTCOM" },
        { value: "ALUMINIUM", label: "ALUMINIUM", InstrumentType: "FUTCOM" },
        { value: "COPPER", label: "COPPER", InstrumentType: "FUTCOM" },
        { value: "COTTONCNDY", label: "COTTONCNDY", InstrumentType: "FUTCOM" },
        { value: "CRDOCTNO23", label: "CRDOCTNO23", InstrumentType: "FUTCOM" },
        { value: "CRMOCTNO23", label: "CRMOCTNO23", InstrumentType: "FUTCOM" },
        { value: "CRUDEOIL", label: "CRUDEOIL", InstrumentType: "FUTCOM" },
        { value: "CRUDEOILM", label: "CRUDEOILM", InstrumentType: "FUTCOM" },
        { value: "CTNNOVJA24", label: "CTNNOVJA24", InstrumentType: "FUTCOM" },
        { value: "GDMOCTNO23", label: "GDMOCTNO23", InstrumentType: "FUTCOM" },
        { value: "GLDDECFE24", label: "GLDDECFE24", InstrumentType: "FUTCOM" },
        { value: "GLDOCTDE23", label: "GLDOCTDE23", InstrumentType: "FUTCOM" },
        { value: "GLDOCTFE24", label: "GLDOCTFE24", InstrumentType: "FUTCOM" },
        { value: "GOLD", label: "GOLD", InstrumentType: "FUTCOM" },
        { value: "GOLDGUINEA", label: "GOLDGUINEA", InstrumentType: "FUTCOM" },
        { value: "GOLDM", label: "GOLDM", InstrumentType: "FUTCOM" },
        { value: "GOLDPETAL", label: "GOLDPETAL", InstrumentType: "FUTCOM" },
        { value: "KAPAS", label: "KAPAS", InstrumentType: "FUTCOM" },
        { value: "LEAD", label: "LEAD", InstrumentType: "FUTCOM" },
        { value: "LEADMINI", label: "LEADMINI", InstrumentType: "FUTCOM" },
        { value: "MENTHAOIL", label: "MENTHAOIL", InstrumentType: "FUTCOM" },
        { value: "NATGASMINI", label: "NATGASMINI", InstrumentType: "FUTCOM" },
        { value: "NATURALGAS", label: "NATURALGAS", InstrumentType: "FUTCOM" },
        { value: "NICKEL", label: "NICKEL", InstrumentType: "FUTCOM" },
        { value: "SILVER", label: "SILVER", InstrumentType: "FUTCOM" },
        { value: "SILVERM", label: "SILVERM", InstrumentType: "FUTCOM" },
        { value: "SILVERMIC", label: "SILVERMIC", InstrumentType: "FUTCOM" },
        { value: "SLMNOVFE24", label: "SLMNOVFE24", InstrumentType: "FUTCOM" },
        { value: "SLVDECMA24", label: "SLVDECMA24", InstrumentType: "FUTCOM" },
        { value: "ZINC", label: "ZINC", InstrumentType: "FUTCOM" },
        { value: "ZINCMINI", label: "ZINCMINI", InstrumentType: "FUTCOM" },
      ],
      "MCX OPT": [
        { value: "COPPER", label: "COPPER", InstrumentType: "OPTFUT" },
        { value: "CRUDEOIL", label: "CRUDEOIL", InstrumentType: "OPTFUT" },
        { value: "GOLD", label: "GOLD", InstrumentType: "OPTFUT" },
        { value: "GOLDM", label: "GOLDM", InstrumentType: "OPTFUT" },
        { value: "NATURALGAS", label: "NATURALGAS", InstrumentType: "OPTFUT" },
        { value: "SILVER", label: "SILVER", InstrumentType: "OPTFUT" },
        { value: "SILVERM", label: "SILVERM", InstrumentType: "OPTFUT" },
        { value: "ZINC", label: "ZINC", InstrumentType: "OPTFUT" },
      ],
      FOREX: [
        {
          symbol: "1",
          value: "EUR/USD",
          label: "EUR/USD",
          InstrumentType: "FUTCUR",
        },
        {
          symbol: "1984",
          value: "XAU/USD",
          label: "XAU/USD",
          InstrumentType: "FUTCUR",
        },
        // {
        //   symbol: "664",
        //   value: "EURINR",
        //   label: "EURINR",
        //   InstrumentType: "FUTCUR",
        // },
        // {
        //   symbol: "39",
        //   value: "GBPUSD",
        //   label: "GBPUSD",
        //   InstrumentType: "FUTCUR",
        // },
        // {
        //   symbol: "1051",
        //   value: "JPYINR",
        //   label: "JPYINR",
        //   InstrumentType: "FUTCUR",
        // },
        // {
        //   symbol: "25",
        //   value: "USDINR",
        //   label: "USDINR",
        //   InstrumentType: "FUTCUR",
        // },
        // {
        //   symbol: "20",
        //   value: "USDJPY",
        //   label: "USDJPY",
        //   InstrumentType: "FUTCUR",
        // },
      ],
    },
    option_types: [
      {
        label: "CE",
        value: "CE",
      },
      {
        label: "PE",
        value: "PE",
      },
    ],
  },
  trading: {
    orderType: [
      {
        label: "Auto Square Off",
        value: "autosquareup",
      },
      {
        label: "B/F",
        value: "B/F",
      },
      {
        label: "C/F",
        value: "C/F",
      },
      {
        label: "Close",
        value: "close",
      },
      {
        label: "Closeall",
        value: "closeall",
      },
      // {
      //   label: "Closeinprofit",
      //   value: "closeinprofit",
      // },
      // {
      //   label: "Closeinloss",
      //   value: "closeinloss",
      // },
      {
        label: "Intraday",
        value: "intraday",
      },
      {
        label: "Intradaylimit",
        value: "intradaylimit",
      },
      {
        label: "limit",
        value: "limit",
      },
      // {
      //   label: "Limitexecute",
      //   value: "limitexecute",
      // },
      {
        label: "Market",
        value: "market",
      },
      {
        label: "Rollover",
        value: "rollover",
      },
      {
        label: "IntradayClose",
        value: "intradayclose",
      },
      {
        label: "ExpiryClose",
        value: "expiryclose",
      },
    ],
  },
  clientList: [
    {
      id: 1,
      name: "John Doe",
      lastMessage: "Hey, how's it going?",
      lastMessageTime: "10:30 AM",
    },
    {
      id: 2,
      name: "Jane Smith",
      lastMessage: "Sure, let's meet at the cafe.",
      lastMessageTime: "11:45 AM",
    },
    {
      id: 3,
      name: "Bob Johnson",
      lastMessage: "I'll send you the document shortly.",
      lastMessageTime: "2:15 PM",
    },
    {
      id: 4,
      name: "Alice Brown",
      lastMessage: "Thanks for your help!",
      lastMessageTime: "3:30 PM",
    },
    {
      id: 5,
      name: "Charlie White",
      lastMessage: "Looking forward to the event.",
      lastMessageTime: "5:00 PM",
    },
    {
      id: 6,
      name: "Eva Davis",
      lastMessage: "Can you review this code?",
      lastMessageTime: "6:20 PM",
    },
    {
      id: 7,
      name: "Frank Taylor",
      lastMessage: "See you tomorrow!",
      lastMessageTime: "8:45 PM",
    },
    {
      id: 8,
      name: "Grace Miller",
      lastMessage: "Sure, I'll be there.",
      lastMessageTime: "9:30 PM",
    },
    {
      id: 9,
      name: "Henry Wilson",
      lastMessage: "Let's schedule a meeting.",
      lastMessageTime: "10:15 PM",
    },
    {
      id: 10,
      name: "Ivy Martinez",
      lastMessage: "Check out this article.",
      lastMessageTime: "11:00 PM",
    },
    {
      id: 11,
      name: "Jack Anderson",
      lastMessage: "What's the status?",
      lastMessageTime: "11:45 PM",
    },
    {
      id: 12,
      name: "Katherine Harris",
      lastMessage: "Happy birthday!",
      lastMessageTime: "12:30 AM",
    },
    {
      id: 13,
      name: "Leo Turner",
      lastMessage: "I'll call you later.",
      lastMessageTime: "1:15 AM",
    },
    {
      id: 14,
      name: "Mia Clark",
      lastMessage: "Thanks for the update.",
      lastMessageTime: "2:00 AM",
    },
    {
      id: 15,
      name: "Noah Adams",
      lastMessage: "Let's plan for the weekend.",
      lastMessageTime: "3:00 AM",
    },
  ],
  brokerList: [
    {
      id: 1,
      name: "Broker 1",
      lastMessage: "I have a new investment opportunity for you.",
      lastMessageTime: "10:30 AM",
    },
    {
      id: 2,
      name: "Broker 2",
      lastMessage: "The market is showing positive trends.",
      lastMessageTime: "11:45 AM",
    },
    {
      id: 3,
      name: "Broker 3",
      lastMessage: "Consider diversifying your portfolio.",
      lastMessageTime: "2:15 PM",
    },
    {
      id: 4,
      name: "Broker 4",
      lastMessage: "Let's discuss your financial goals.",
      lastMessageTime: "3:30 PM",
    },
    {
      id: 5,
      name: "Broker 5",
      lastMessage: "Reviewing the latest market reports.",
      lastMessageTime: "5:00 PM",
    },
    {
      id: 6,
      name: "Broker 6",
      lastMessage: "Here are some potential investment options.",
      lastMessageTime: "6:20 PM",
    },
    {
      id: 7,
      name: "Broker 7",
      lastMessage: "Market volatility expected in the coming weeks.",
      lastMessageTime: "8:45 PM",
    },
    {
      id: 8,
      name: "Broker 8",
      lastMessage: "Providing updates on your investment portfolio.",
      lastMessageTime: "9:30 PM",
    },
    {
      id: 9,
      name: "Broker 9",
      lastMessage: "Exploring high-yield investment opportunities.",
      lastMessageTime: "10:15 PM",
    },
    {
      id: 10,
      name: "Broker 10",
      lastMessage: "Market analysis: Bullish vs Bearish trends.",
      lastMessageTime: "11:00 PM",
    },
    {
      id: 11,
      name: "Broker 11",
      lastMessage: "Discussing tax-efficient investment strategies.",
      lastMessageTime: "11:45 PM",
    },
    {
      id: 12,
      name: "Broker 12",
      lastMessage: "Your investment performance overview.",
      lastMessageTime: "12:30 AM",
    },
    {
      id: 13,
      name: "Broker 13",
      lastMessage: "The importance of long-term financial planning.",
      lastMessageTime: "1:15 AM",
    },
    {
      id: 14,
      name: "Broker 14",
      lastMessage: "Monitoring market trends for your investments.",
      lastMessageTime: "2:00 AM",
    },
    {
      id: 15,
      name: "Broker 15",
      lastMessage: "Let's schedule a portfolio review meeting.",
      lastMessageTime: "3:00 AM",
    },
  ],
};
export const RentOption = [
  {
    label: "Owner",
    value: "owner",
    value2: "owner",
    authorityLevel: 0,
  },
  {
    label: "Master Admin",
    value: "masterAdmin",
    value2: "MasterAdmin",
    authorityLevel: 1,
  },
  {
    label: "Super Admin",
    value: "superAdmin",
    value2: "SuperAdmin",
    authorityLevel: 2,
  },
  {
    label: "Admin",
    value: "admin",
    value2: "Admin",
    authorityLevel: 3,
  },
  {
    label: "Master",
    value: "master",
    value2: "Master",
    authorityLevel: 4,
  },
  {
    label: "Dealer",
    value: "dealer",
    value2: "Dealer",
    authorityLevel: 5,
  },
];

export let optionDays = [];
for (let i = 0; i <= 90; i++) {
  optionDays.push({ label: `${i} days`, value: i === 0 ? "0" : i });
}

export const allOption = [
  {
    label: "All",
    value: "all",
    value2: "all",
    authorityLevel: 8,
  },
  {
    label: "Owner",
    value: "owner",
    value2: "owner",
    authorityLevel: 0,
  },
  {
    label: "Master Admin",
    value: "masterAdmin",
    value2: "MasterAdmin",
    authorityLevel: 1,
  },
  {
    label: "Super Admin",
    value: "superAdmin",
    value2: "SuperAdmin",
    authorityLevel: 2,
  },
  {
    label: "Admin",
    value: "admin",
    value2: "Admin",
    authorityLevel: 3,
  },
  {
    label: "Master",
    value: "master",
    value2: "Master",
    authorityLevel: 4,
  },
  {
    label: "Dealer",
    value: "dealer",
    value2: "Dealer",
    authorityLevel: 5,
  },
  {
    label: "Customer",
    value: "customer",
    value2: "Customer",
    authorityLevel: 6,
  },
  {
    label: "Broker",
    value: "broker",
    value2: "Broker",
    authorityLevel: 7,
  },
];
export const ThemeDropDowns = [
  {
    authorityLevel: 1,
    title: "Master Admin",
    placeholder: "Select Master Admin",
    name: "market",
    width: "98%",
  },
  {
    authorityLevel: 2,
    title: "Super Admin",
    placeholder: "Select Super Admin",
    name: "market",
    width: "98%",
  },
  {
    authorityLevel: 3,
    title: "Admin",
    placeholder: "Select Admin",
    name: "market",
    width: "98%",
  },
  {
    authorityLevel: 4,
    title: "Master",
    placeholder: "Select Master",
    name: "market",
    width: "98%",
  },
  {
    authorityLevel: 5,
    title: "Dealer",
    placeholder: "Select Dealer",
    name: "market",
    width: "98%",
  },

  {
    authorityLevel: 7,
    title: "Broker",
    placeholder: "Select Broker",
    name: "market",
    width: "98%",
  },
  {
    authorityLevel: 6,
    title: "Customer",
    placeholder: "Select Customer",
    name: "market",
    width: "98%",
  },
];
export const OptionMarket = {
  "Master Admin": "masterAdmin",
  "Super Admin": "superAdmin",
  Admin: "admin",
  Master: "master",
  Dealer: "dealer",
  Broker: "broker",
  Customer: "customer",
};
export const tradeLogFilterColumns = [
  { title: "Device", value: "deviceType", initalShow: true, disable: false },
  { title: "Order Time", value: "tradeTime", initalShow: true, disable: false },
  { title: "Client Info", value: "client", initalShow: true, disable: true },
  {
    title: "Trade Id",
    value: "tradeNumber",
    initalShow: true,
    disable: false,
  },
  { title: "Market", value: "marketName", initalShow: true, disable: false },
  { title: "Script", value: "symbol", initalShow: true, disable: true },
  { title: "Title", value: "title", initalShow: true, disable: false },
  {
    title: "Order Type",
    value: "title",
    initalShow: true,
    disable: false,
  },
  { title: "B/S", value: "tradePattern", initalShow: true, disable: false },
  { title: "QTY (LOT)", value: "lot", initalShow: true, disable: false },
  {
    title: "Order Price",
    value: "tradePrice",
    initalShow: true,
    disable: false,
  },
  {
    title: "User IP",
    value: "userIp",
    initalShow: true,
    disable: false,
  },
  { title: "Remark", value: "remark", initalShow: true, disable: false },
  {
    title: "Modified By",
    value: "modifiedBy",
    initalShow: true,
    disable: false,
  },
  { title: "LTP", value: "stoploss", initalShow: false, disable: false },
  { title: "High", value: "high", initalShow: false, disable: false },
  { title: "Low", value: "low", initalShow: false, disable: false },
  { title: "Bid", value: "Bid", initalShow: false, disable: false },
  { title: "Ask", value: "Ask", initalShow: false, disable: false },
  { title: "Action", value: "action", initalShow: true, disable: false },
];
