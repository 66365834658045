import { Radio, Row, Table } from "antd";
import styled from "styled-components";

export const options1 = [
  { label: "Client Wise", value: 0 },
  { label: "Script Wise", value: 1 },
];
export const options2 = [
  { label: "All", value: 2 },
  { label: "Outstanding", value: 3 },
];
export const style = {
  colCardText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tableWrraperDiv: {
    // backgroundColor: "#fff",
    marginTop: "2rem",
    borderRadius: "1rem",
  },
  collapse: {
    backgroundColor: "#fff",
  },
};
export const StyleRow = styled(Row)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    justify-content: start;
  }
`;
export const TableFooterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-end;
`;
export const FooterItem = styled.div`
  text-align: right;
  margin-right: "5rem";
`;
export const CardTitleText = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.theme.token.textLableColour};
  @media (max-width: 1079px) {
    font-size: 0.8rem;
  }
`;
export const CardDetailText = styled.div`
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.token.fontWeightStrong};
  color: ${(props) => props.theme.token.textLableColour};
  @media (max-width: 1079px) {
    font-size: 1.3rem;
  }
`;
export const CloseImage = styled.img`
  width: 2rem;
  height: 2rem;
  @media (max-width: 1079px) {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
export const MarketTypeRadio = styled(Radio.Group)`
  width: 100%;

  & .ant-radio-button-wrapper {
    font-size: 1rem;
    width: ${(props) => (props.width ? props.width : "auto")};
  }
  font-weight: 450;
`;
export const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
export const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }

  .sell-row {
    /* background-color: #ffcccb;  */
    td:last-child {
      border-right: 5px solid #ffb3b3 !important;
    }
    &:hover > td {
      background-color: #ffb3b3 !important; /* Darker red on hover */
    }
  }

  .close-row {
    td:last-child {
      border-right: 5px solid #ffd700 !important;
    }
    &:hover > td {
      background-color: #fff5b1 !important;
    }
  }

  .buy-row {
    /* background-color: #90ee90; Light green for create rows */
    td:last-child {
      border-right: 5px solid #90ee90 !important;
    }
    &:hover > td {
      background-color: #90ee90 !important;
    }
  }
`;
export const TitleRow = styled(Row)`
  width: 60%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
