import { Divider, Modal, Row, Switch } from "antd";
import React, { useState } from "react";
import { FilterColumn, FilterText } from "./style";
import { useDispatch } from "react-redux";
import { setColumnFilter } from "../../store/slice/marketWatchSlice";
import { setTradingTradeColumn } from "../../store/slice/globleSlice";

const TableFilterModal = ({ column, setShowFilter, checkList, filtertype }) => {
  //state
  const [selectedColumn, setSelectedColumn] = useState(checkList);

  const dispatch = useDispatch();
  //function
  const handleCancel = () => {
    setShowFilter(false);
  };

  const handleChange = (value, name) => {
    if (value) {
      setSelectedColumn([...selectedColumn, name?.value]);
    } else {
      setSelectedColumn(selectedColumn?.filter((el) => el !== name?.value));
    }
  };

  const handleSubmit = () => {
    switch (filtertype) {
      case "marketWatch":
        dispatch(setColumnFilter(selectedColumn));
        break;
      case "trade":
        dispatch(
          setTradingTradeColumn({
            name: "tradingTradeColumn",
            value: selectedColumn,
          })
        );
        break;
      case "tradeLog":
        dispatch(
          setTradingTradeColumn({
            name: "tradeLogColumn",
            value: selectedColumn,
          })
        );
        break;
      default:
        break;
    }

    setShowFilter(false);
  };
  return (
    <div>
      <Modal
        title="Columns Filter"
        open={true}
        onCancel={handleCancel}
        onOk={handleSubmit}
      >
        <Divider style={{ margin: "0px" }} />
        <Row gutter={[16, 10]} style={{ marginTop: "1rem" }}>
          {column?.map((el) => {
            return (
              <FilterColumn span={12} key={el?.title}>
                <Switch
                  defaultChecked={selectedColumn?.includes(el?.value)}
                  // checkedChildren="Show"
                  // unCheckedChildren="Hide"
                  onChange={(value) => handleChange(value, el)}
                  disabled={el?.disable}
                />
                <div style={{ marginTop: "0.1rem" }}>
                  <FilterText strong>{el?.title}</FilterText>
                </div>
              </FilterColumn>
            );
          })}
        </Row>
      </Modal>
    </div>
  );
};

export default React.memo(TableFilterModal);
