import React, { useEffect, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Pagination, Popover, Row, Tag, notification } from "antd";
import ThemeCardTable from "../../components/ThemeCardTable";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQuery } from "@tanstack/react-query";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import ValanComponant from "../../components/ValanComponant/ValanComponant";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserClearFilter,
  setValanValue,
} from "../../store/slice/globleSlice";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import {
  findMarket,
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import { limitNumberLength } from "../../components/SocketLiveData";
import GetDate from "../../components/DatePicker/GetDate";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { PaginationRow, StyledButton } from "../../styles/commonStyle";

function BrokerageReport() {
  //state
  // const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const [totalCounts, setTotalCounts] = useState(0);
  const [getValan, setGetValan] = useState(false);
  const valueRef = useRef({});
  const dispatch = useDispatch();
  const { params, setSearchParams } = useGetQueryParams();

  //redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

  //life cycle
  useEffect(() => {
    dispatch(setValanValue(true));
    setSearchParams({
      ...params,
      userId: params?.userId ? params?.userId : loginId,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginId });
    }
  }, [params]);

  // api call
  const getBrokerageDetails = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    let payload = {
      userId: Number(params?.userId),
      marketName: params?.marketName || null,
      symbolName: params?.symbolName || null,
      valan: params?.valan || valueRef?.current?.valan || null,
      endDate: params?.endDate || null,
      startDate: params?.startDate || null,
    };

    let response = await APICall(
      "post",
      `${EndPoints.getBrokerage}?page=${params?.page || 1}&limit=${
        params?.limit || 10
      }`,
      payload
    );

    if (response.status === 200) {
      setTotalCounts(response?.data?.data?.totalCount);
      return response?.data?.data;
    } else {
      return [];
    }
  };

  const {
    isLoading,
    error: BrokerageError,
    data: BrokerageData,
  } = useQuery({
    queryKey: ["brokerageDetails", params],
    queryFn: getBrokerageDetails,
    enabled:
      Object.keys(params).length > 0 && valueRef.current.valan !== undefined,
  });

  if (BrokerageError) {
    notification.error({ message: BrokerageError?.message });
  }

  // function
  // const handleOpenChange = async (index, record, column) => {
  //   setOpenPopoverIndex(
  //     index === openPopoverIndex?.index && openPopoverIndex?.column === column
  //       ? null
  //       : { index: index, column: column }
  //   );
  // };

  const content = (record, type) => {
    let brokerName =
      type == 1
        ? record?.brokerOneName
        : type === 2
        ? record?.brokerTwoName
        : record?.brokerThreeName;

    let brokerId =
      type == 1
        ? record?.brokerOneId
        : type === 2
        ? record?.brokerTwoId
        : record?.brokerThreeId;
    return (
      <Row>
        <Col span={6}>
          <Tag
            color="magenta"
            bordered={false}
            style={{
              fontSize: "1rem",
              marginLeft: "0.5rem",
            }}
          >
            {"BR"}
          </Tag>
        </Col>

        <Col>{`: ${brokerName} (${brokerId})`}</Col>
      </Row>
    );
  };

  const handleSubmit = () => {
    let postData = valueRef.current;

    let payload = {
      userId: postData?.userId || loginId,
      marketName: postData?.value?.marketName || null,
      symbolName: postData?.value?.symbolName || null,
      valan: postData?.valan || null,
      endDate: postData?.endDate?.toISOString() || null,
      startDate: postData?.startDate?.toISOString() || null,
      page: 1,
    };
    for (let key in payload) {
      if (!payload[key]) {
        delete payload[key];
      }
    }
    setSearchParams(payload);
  };

  const handleReset = () => {
    // valueRef.current = {};
    dispatch(setUserClearFilter(true));
    setSearchParams({ userId: loginId });
  };

  const onChagePage = (page) => {
    setSearchParams({ ...params, page: page });
  };

  // column
  const columns = [
    isLoading
      ? { title: "TRADE DATE", dataIndex: "tradeTime", key: "tradeTime" }
      : {
          title: "TRADE DATE",
          dataIndex: "tradeTime",
          key: "tradeTime",
          align: "left",
          width: "8rem",
          render: (text, record) => {
            const finalDate = valanBillDate(record?.tradeTime, true);
            return <div>{finalDate}</div>;
          },
        },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "12rem",
      render: (text, record) => {
        return (
          !isLoading && <div>{`${record?.client} (${record?.clientName})`}</div>
        );
      },
    },
    {
      title: "SCRIPT",
      dataIndex: "script",
      key: "script",
      align: "left",
      width: "12rem",
      render: (text, record) => {
        const finalDate = valanBillDate(record?.expiryDate, false);
        const opt = record?.marketName?.endsWith("OPT");
        const name = opt
          ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
              record?.strikePrice ? record?.strikePrice : ""
            }`
          : record?.symbolName;
        return (
          !isLoading && (
            <div>{`${name} ${record?.expiryDate ? finalDate : ""}`}</div>
          )
        );
      },
    },
    {
      title: "ORDER TYPE",
      dataIndex: "title",
      key: "title",
      align: "left",
      width: "6rem",
    },
    {
      title: "B/S",
      dataIndex: "tradeType",
      key: "tradeType",
      align: "left",
      width: "6rem",
    },
    {
      title: "QTY (LOT)",
      dataIndex: "tradeLot",
      key: "tradeLot",
      align: "left",
      width: "9rem",
      render: (text, record) => {
        const lot = formatNumber(record?.tradeLot);
        // const intValue = lot?.toFixed(2).replace(/\.?0+$/, "");
        const quantity = formatNumber(record?.tradeQty);
        const data =
          record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
        return !isLoading && <div>{data}</div>;
      },
    },
    {
      title: "PRICE",
      dataIndex: "tradePrice",
      key: "tradePrice",
      align: "left",
      width: "8rem",
      render: (text, record) => {
        const price = findMarket(record?.marketName)
          ? limitNumberLength(record?.tradePrice)
          : record?.tradePrice?.toFixed(2);
        return !isLoading && <span>{price}</span>;
      },
    },
    {
      title: "TOTAL BROKERAGE",
      dataIndex: "totalBrokerage",
      key: "totalBrokerage",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        return !isLoading && <span>{formatToINR(record?.totalBrokerage)}</span>;
      },
    },
    {
      title: "MY BROKERAGE",
      dataIndex: "myBrokerage",
      key: "myBrokerage",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        return !isLoading && <span>{formatToINR(record?.myBrokerage)}</span>;
      },
    },
    {
      title: "UPLINE BROKERAGE",
      dataIndex: "uplineBrokerage",
      key: "uplineBrokerage",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        return (
          !isLoading && <span>{formatToINR(record?.uplineBrokerage)}</span>
        );
      },
    },
    {
      title: "DOWNLINE BROKERAGE",
      dataIndex: "downlineBrokerage",
      key: "downlineBrokerage",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        return (
          !isLoading && <div>{formatToINR(record?.downlineBrokerage)}</div>
        );
      },
    },
    // {
    //   title: "B1",
    //   dataIndex: "b1",
    //   key: "b1",
    //   align: "center",
    //   width: "7rem",
    //   render: (text, record, index) => {
    //     const isOpen =
    //       openPopoverIndex?.index === index && openPopoverIndex?.column == 1;
    //     return (
    //       !isLoading &&
    //       (record?.brokerOneId ? (
    //         <Popover
    //           content={() => content(record, 1)}
    //           // title="Upline List"
    //           trigger="click"
    //           placement="top"
    //           open={isOpen}
    //           onOpenChange={() => handleOpenChange(index, record, 1)}
    //         >
    //           <StyleSpan>{formatToINR(record?.brokerOne)}</StyleSpan>
    //         </Popover>
    //       ) : (
    //         <>{formatToINR(record?.brokerOne)}</>
    //       ))
    //     );
    //   },
    // },
    // {
    //   title: "B2",
    //   dataIndex: "b2",
    //   key: "b2",
    //   align: "center",
    //   width: "7rem",
    //   render: (text, record, index) => {
    //     const isOpen =
    //       openPopoverIndex?.index === index && openPopoverIndex?.column == 2;
    //     return (
    //       !isLoading &&
    //       (record?.brokerTwoId ? (
    //         <Popover
    //           content={() => content(record, 2)}
    //           // title="Upline List"
    //           trigger="click"
    //           placement="top"
    //           open={isOpen}
    //           onOpenChange={() => handleOpenChange(index, record, 2)}
    //         >
    //           <StyleSpan> {formatToINR(record?.brokerTwo)}</StyleSpan>
    //         </Popover>
    //       ) : (
    //         <>{formatToINR(record?.brokerTwo)}</>
    //       ))
    //     );
    //   },
    // },
    // {
    //   title: "B3",
    //   dataIndex: "b3",
    //   key: "b3",
    //   align: "center",
    //   width: "7rem",
    //   render: (text, record, index) => {
    //     const isOpen =
    //       openPopoverIndex?.index === index && openPopoverIndex?.column == 3;
    //     return (
    //       !isLoading &&
    //       (record?.brokerThreeId ? (
    //         <Popover
    //           content={() => content(record, 3)}
    //           // title="Upline List"
    //           trigger="click"
    //           placement="top"
    //           open={isOpen}
    //           onOpenChange={() => handleOpenChange(index, record, 3)}
    //           style={{ cursor: "pointer" }}
    //         >
    //           <StyleSpan> {formatToINR(record?.brokerThree)} </StyleSpan>
    //         </Popover>
    //       ) : (
    //         <>{formatToINR(record?.brokerThree)}</>
    //       ))
    //     );
    //   },
    // },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[10, 15]}>
          <MarketScriptList valueRef={valueRef} />
          <ValanComponant valueRef={valueRef} setGetValan={setGetValan} />
          <GetDate valueRef={valueRef} />
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row gutter={[10, 15]} style={{ marginTop: "2rem" }} justify="start">
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleSubmit}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleReset}>
              Clear Search
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={BrokerageData?.report}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={isLoading}
        filtertype="brokerage"
        footer={BrokerageData?.report?.length > 0 ? true : false}
      />
      <PaginationRow>
        <Pagination
          current={params?.page || 1}
          total={totalCounts}
          pageSize={params?.limit || 10}
          onChange={onChagePage}
          showSizeChanger={false}
        />
      </PaginationRow>
    </>
  );
}

export default BrokerageReport;
