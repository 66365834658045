import React, { useEffect, useState } from "react";
import ThemeInput from "../../../../components/ThemeInput";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Spin,
  notification,
} from "antd";
import { LoginStatusOptions, UserStatusOptions } from "../style";
import { useMutation, useQuery } from "@tanstack/react-query";
import APICall from "../../../../APICall";
import EndPoints from "../../../../APICall/EndPoints";
import { useSelector } from "react-redux";
import { MarketTypeRadio } from "../../../TradingPosition/style";
import { useCheckPassward } from "../../../../APICall/apiHooks";

const initalState = {
  status: null,
  message: "",
  loginStatus: null,
};

const StatusButton = ({ setShowStatus, item, refetch }) => {
  // status
  const [accountStatus, setAccountStatus] = useState(initalState);
  const [loginStatus, setLoginStatus] = useState(null);
  const [passward, setPassward] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [form] = Form.useForm();

  //redux
  const addition = useSelector((state) => state?.authSlice?.additionalSharing);

  // delete account api call
  const {
    isLoading: loadingdeleteAccount,
    mutate: deleteAccountMutate,
    data: deleteData,
  } = useMutation({
    mutationKey: "deleteAccount",
    mutationFn: () => {
      return APICall("delete", `${EndPoints?.deleteUser}${item?.userId}`);
    },
    onSuccess: (res) => {
      if (res?.status === 200) {
        notification.success({ message: res?.data?.message });
        setShowStatus({ flage: false, item: null });
        refetch();
      } else {
        notification.error({ message: res?.data?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  // custome hook for passward verification
  const { passwardLoding, passwardMutate } =
    useCheckPassward(deleteAccountMutate);

  //api
  const getStatus = async (userId) => {
    let response = await APICall("get", `${EndPoints.getUserStatus}${userId}`);
    return response?.data?.data;
  };

  const {
    isLoading: getStatusLoading,
    isFetching,
    error: getStatusError,
    data,
  } = useQuery({
    queryKey: ["getStatus"],
    queryFn: () => getStatus(item?.userId),
    refetchOnMount: true,
  });

  if (getStatusError) notification.error({ message: getStatusError?.message });

  const updateStatus = async (payload) => {
    if (payload.status !== "Block") {
      delete payload.message;
    }
    let response = await APICall(
      "post",
      `${EndPoints?.updateUserStatus}${item?.userId}`,
      payload
    );
    return response;
  };

  const {
    isLoading: loadingUpdateStatus,
    mutate: updateStatusMutate,
    data: statusData,
  } = useMutation({
    mutationKey: "updateStatus",
    mutationFn: (payload) => updateStatus(payload),
    onSuccess: (res) => {
      if (res?.status === 200) {
        notification.success({ message: res?.data?.message });
        setShowStatus({ flage: false, item: null });
      } else {
        notification.error({ message: res?.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  // life cycle
  useEffect(() => {
    let obj = {
      login: "Login",
      logout: "Logout",
    };
    if (data) {
      setAccountStatus({
        ...accountStatus,
        status: data?.status === "customBlock" ? "Block" : data?.status,
        message: data?.blockedMessage,
      });

      setLoginStatus(obj[data?.loginStatus]);
    }
  }, [data]);

  // function
  const onChangeRadio = async ({ target: { name, value } }) => {
    if (value === "Login") {
      setAccountStatus({
        ...accountStatus,
        status: data?.status,
      });
      setLoginStatus(value);
    } else if (value === "Logout") {
      setAccountStatus((pre) => ({ ...pre, status: value }));
      setLoginStatus(value);
    } else {
      setAccountStatus((pre) => ({ ...pre, status: value }));
      setLoginStatus(data?.loginStatus);
    }
  };

  const handleMessage = (e) => {
    let value = e.target.value;
    setAccountStatus({ ...accountStatus, message: value });
  };

  const handleOkStatus = () => {
    updateStatusMutate(accountStatus);
  };

  const handleCancelStatus = () => {
    setShowStatus({ flage: false, item: null });
  };

  const handleDeleteAccount = async () => {
    if (showDelete) {
      form.submit();
    } else {
      setShowDelete(true);
    }
  };

  const handleFinish = (value) => {
    // checkPasswardMutate({ password: passward });
    passwardMutate(passward);
  };

  const handleFinishFailed = (value) => {
    console.log("failed", value);
  };

  return (
    <>
      <Modal
        open={true}
        title={`${item?.accountName}(${item?.userId})`}
        onOk={handleOkStatus}
        onCancel={handleCancelStatus}
        confirmLoading={loadingUpdateStatus}
      >
        {getStatusLoading || isFetching ? (
          <Row align={"center"}>
            <Spin active />
          </Row>
        ) : (
          <Row
            justify={"space-around"}
            gutter={[10, 10]}
            style={{ marginTop: "1rem" }}
          >
            <Col>
              <MarketTypeRadio
                name="loginStatus"
                options={LoginStatusOptions}
                onChange={onChangeRadio}
                value={loginStatus}
                optionType="button"
                buttonStyle="solid"
                disabled={data?.loginStatus === "logout"}
                // width={"100%"}
              />
            </Col>
            <Col>
              <MarketTypeRadio
                name="status"
                options={UserStatusOptions}
                onChange={onChangeRadio}
                value={accountStatus?.status}
                optionType="button"
                buttonStyle="solid"
                // width={"100%"}
              />
            </Col>
          </Row>
        )}
        {accountStatus.status === "Block" ? (
          <Row align={"center"} style={{ marginTop: "2rem" }}>
            <ThemeInput
              wrapperwidth={"90%"}
              title="Message"
              inputProps={{
                placeholder: "Enter Message Here",
                value: accountStatus?.message,
                onChange: handleMessage,
              }}
            />
          </Row>
        ) : null}
        {addition?.clientDelete && (
          <Row align={"center"} style={{ marginTop: "2rem" }}>
            <Button
              className="redsUserTableBtn"
              style={{ width: "60%" }}
              onClick={() => setShowDelete(true)}
            >
              Delete User
            </Button>
          </Row>
        )}
      </Modal>
      <Modal
        title="Delete Account"
        open={showDelete}
        onCancel={() => setShowDelete(false)}
        footer={false}
      >
        <Form
          form={form}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          <Row
            justify={"space-around"}
            align={"center"}
            gutter={[10, 10]}
            style={{ marginTop: "2rem" }}
          >
            <Col span={16}>
              <Form.Item name="passward" rules={[{ required: true }]}>
                <Input
                  title="Passward"
                  // type="password"
                  name="passward"
                  wrapperwidth={"80%"}
                  placeholder="Enter Your Password to Delete Account"
                  value={passward}
                  onChange={(e) => setPassward(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Popconfirm
                title="Delete Account"
                placement="topRight"
                description="Are you sure to delete this account permanently?"
                onConfirm={handleDeleteAccount}
              >
                <Button
                  className="redsUserTableBtn"
                  style={{ marginBottom: "2rem" }}
                  loading={loadingdeleteAccount || passwardLoding}
                  // onClick={handleDeleteAccount}
                >
                  Delete
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(StatusButton);
