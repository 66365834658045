import { useDispatch, useSelector } from "react-redux";
import { setExprirySetting } from "../../store/slice/expirySettingSlice";
import ThemeCard from "../../components/ThemeCard";
import { Col, Row } from "antd";
import { ExpirySettingRow, Label } from "./style";
import ThemeDropDown from "../../components/ThemeDropDown";
import { options } from "../../Helper/constant";
import React from "react";

const NseEquRender = ({ tab }) => {
  const dispatch = useDispatch();

  // redux
  const expiryValue = useSelector((state) => state.expirySettingSlice?.[tab]);

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate" || name === "valanEndingSquareUpRate") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    }
  };

  return (
    <>
      <ThemeCard title="POSITION" bordered={false}>
        <ExpirySettingRow>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={
                tab === "FOREX" || tab === "GLOBEX"
                  ? options.user.Expiry.FOREX_OPTION
                  : options.user.Expiry.MCX_OPTION
              }
              $onChange={handleChange}
              $value={expiryValue.intraday.squareUpRate}
            />
          </Col>
        </ExpirySettingRow>
        <Row align={"center"} style={{ marginTop: "1rem" }}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={
                tab === "FOREX" || tab === "GLOBEX"
                  ? options.user.Expiry.FOREX_OPTION
                  : options.user.Expiry.MCX_OPTION
              }
              $onChange={handleChange}
              $value={expiryValue.intraday?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
    </>
  );
};

export default React.memo(NseEquRender);
