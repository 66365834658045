import { useDispatch, useSelector } from "react-redux";
import ThemeInputNumber from "../../../../components/ThemeInputNumber/ThemeInputNumber";
import { Col, Form, InputNumber, Row, Tooltip } from "antd";
import ThemeCard from "../../../../components/ThemeCard";
import AccountDetailsRadioView from "../AccountDetailsRadioView";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { BrokerageDiv, EqualToCol, StyleSpan } from "../style";
import userAccountSlice from "../../../../store/slice/userAccountSlice";
import { updateScriptSlice } from "../../../../store/slice/scriptSettingSlice";
import { setEditData } from "../../../../store/slice/scriptSettingsRequestSlice";

const ClientBrokerageView =
  //  React.memo(
  ({ tab, view, is_edits, form }) => {
    const dispatch = useDispatch();
    //redux
    const accountType = useSelector(
      (state) => state.userAccountSlice?.accountType
    );
    const brokerageFix =
      Form.useWatch(`brokerageType$${tab}`, form) === "fixed";
    const canBlockPercentage =
      Form.useWatch(`partnershipWith`, form) === "brokerage";
    // const minimumBrokerage = Form.useWatch(`minimumBrokerageRequire`, form);
    const showBrokerage = Form.useWatch(`minimumBrokerageRequire`, form);
    let deliverysvalue = Form.useWatch(`deliveryTotal$${tab}`, form);
    const marketGiven = useSelector(
      (state) => state?.globleSlice?.constantValue?.marketAccess
    );
    const parentDetails = useSelector(
      (state) => state.authSlice.MarketAccessDetails
    );
    const marketDetails = useSelector(
      (state) => state?.userAccountSlice?.[tab]
    );
    // console.log("marketDetails", marketDetails);
    const isParentRent = parentDetails?.basicInfo?.partnerShipType === "rent";
    const isRent = parentDetails?.accountDetails?.partnershipWith === "rent";
    const isMaster = accountType === "masterAdmin";
    const isCustomer = accountType === "customer";
    const notEditable =
      (isRent || isMaster) && !marketGiven?.includes(tab) ? false : true;
    const editable = marketGiven?.includes(tab);
    let parentMinimumBrokerage =
      parentDetails?.accountDetails?.minimumBrokerageRequire;
    let pr =
      parentDetails?.brokarageDetails?.[tab]?.remainingPercentage ||
      100 - parentDetails?.brokarageDetails?.[tab]?.percentage;
    // let pr = remainingPercentage==0? 0:remainingPercentage;

    const deliveryTotal = parentDetails?.brokarageDetails?.[tab]?.deliveryTotal;
    const intraDayTotal = parentDetails?.brokarageDetails?.[tab]?.intradayTotal;
    const deliveryBuy = parentDetails?.brokarageDetails?.[tab]?.deliveryBuy;
    const deliverySell = parentDetails?.brokarageDetails?.[tab]?.deliverySell;
    const intradayBuy = parentDetails?.brokarageDetails?.[tab]?.intradayBuy;
    const intradaySell = parentDetails?.brokarageDetails?.[tab]?.intradaySell;

    const handleBrokerageTypeChange = (e) => {
      const value = e.target.value;
      const { brokerageDetails, allScript, additionalSharing } = marketDetails;

      if (is_edits && notEditable && isParentRent) {
        if (brokerageDetails?.brokerageType === value) {
          form.setFields([
            {
              name: `deliveryTotal$${tab}`,
              value: brokerageDetails?.deliveryTotal,
              touched: false,
            },
            {
              name: `intradayTotal$${tab}`,
              value: brokerageDetails?.intradayTotal,
              touched: false,
            },
            {
              name: `deliveryBuy$${tab}`,
              value: brokerageDetails?.deliveryBuy,
              touched: false,
            },
            {
              name: `deliverySell$${tab}`,
              value: brokerageDetails?.deliverySell,
              touched: false,
            },
            {
              name: `intradayBuy$${tab}`,
              value: brokerageDetails?.intradayBuy,
              touched: false,
            },
            {
              name: `intradaySell$${tab}`,
              value: brokerageDetails?.intradaySell,
              touched: false,
            },
            {
              name: `additionalSharing$${tab}`,
              value: additionalSharing?.map((el) => ({
                ...el,
                brokerId: [el?.brokerId],
              })),
              touched: false,
            },
          ]);
          const formatedScript = allScript?.reduce((acc, curr) => {
            if (curr?.scriptName) {
              acc[curr?.scriptName] = curr;
            }
            return acc;
          }, {});
          dispatch(updateScriptSlice({ [tab]: formatedScript }));
          dispatch(setEditData({ tab, edited: [] }));
        } else {
          form.setFieldsValue({
            [`deliveryTotal$${tab}`]: 0,
            [`intradayTotal$${tab}`]: 0,
            [`deliveryBuy$${tab}`]: 0,
            [`deliverySell$${tab}`]: 0,
            [`intradayBuy$${tab}`]: 0,
            [`intradaySell$${tab}`]: 0,
            [`additionalSharing$${tab}`]: additionalSharing?.map((el) => {
              return {
                ...el,
                delivery: 0,
                intraday: 0,
                brokerId: [el?.brokerId],
              };
            }),
          });
          const formatedScript = allScript?.reduce((acc, curr) => {
            if (curr?.scriptName) {
              acc[curr?.scriptName] = {
                ...curr,
                total: null,
                delivery: null,
                intraday: null,
                maxValue: null,
                minValue: null,
              };
            }
            return acc;
          }, {});

          dispatch(updateScriptSlice({ [tab]: formatedScript }));
          const newValue = allScript?.map((el) => {
            return {
              ...el,
              total: null,
              delivery: null,
              intraday: null,
              maxValue: null,
              minValue: null,
            };
          });
          dispatch(setEditData({ tab, edited: newValue }));
        }
      } else {
        const additional = form.getFieldValue(`additionalSharing$${tab}`) || [];
        if (!showBrokerage) {
          form.setFieldsValue({
            [`additionalSharing$${tab}`]: additional?.map((el) => ({
              ...el,
              delivery: 0,
              intraday: 0,
            })),
          });
        } else {
          form.setFieldsValue({
            [`deliveryTotal$${tab}`]: brokerageDetails?.deliveryTotal,
            [`deliveryBuy$${tab}`]: brokerageDetails?.deliveryBuy,
            [`deliverySell$${tab}`]: brokerageDetails?.deliverySell,
            [`intradayTotal$${tab}`]: brokerageDetails?.intradayTotal,
            [`intradayBuy$${tab}`]: brokerageDetails?.intradayBuy,
            [`intradaySell$${tab}`]: brokerageDetails?.intradaySell,
            [`additionalSharing$${tab}`]: additional?.map((el) => ({
              ...el,
              delivery: 0,
              intraday: 0,
            })),
          });
        }

        dispatch(updateScriptSlice({ [tab]: {} }));
        dispatch(setEditData({ tab, edited: [] }));
      }
    };

    return (
      <Form form={form}>
        <ThemeCard
          title={isCustomer ? "Client Brokerage" : "My Partnership"}
          headerColor
          style={{ marginBottom: "2rem" }}
        >
          <Row justify={"center"} style={{ marginBottom: "1rem" }}>
            <Col xs={24} sm={24} md={18} lg={12} xl={8} align={"center"}>
              <AccountDetailsRadioView
                formProps={{
                  name: `brokerageType$${tab}`,
                  initialValue:
                    isMaster || isRent
                      ? ["NSE OPT", "MCX", "MCX OPT"].includes(tab)
                        ? "fixed"
                        : "percentage"
                      : parentDetails?.brokarageDetails[tab]?.brokerageType,
                }}
                disabled={
                  (is_edits && notEditable && !isParentRent) ||
                  tab === "NSE EQU" ||
                  (!isMaster && !isRent && !is_edits && parentMinimumBrokerage)
                  // ||
                  // (parentMinimumBrokerage && !is_edits)
                }
                title={
                  <StyleSpan>
                    <div>Brokerage Type</div>
                    <Tooltip
                      title={`Fix : Brokerage per lot , Percentage : On total value of order (0.01 : on 1,00,000 -> 10 rs.)`}
                      placement="topRight"
                    >
                      <QuestionCircleOutlined style={{ cursor: "pointer" }} />
                    </Tooltip>
                  </StyleSpan>
                }
                onChange={handleBrokerageTypeChange}
                $width={"18rem"}
                name="brokerageType"
                required
                toolTip={true}
                toolTipTitle="⚠️ Warning: Changing brokerage type will reset Client brokerage, brokers data, and script details"
                options={[
                  {
                    value: "fixed",
                    label: "Fix",
                  },
                  {
                    value: "percentage",
                    label: "%",
                  },
                ]}
              />
            </Col>
          </Row>

          <BrokerageDiv>
            {isCustomer ? (
              <div style={{ width: "100%" }}>
                <Row
                  justify={"center"}
                  gutter={[8, 8]}
                  style={{ marginBottom: "2rem" }}
                >
                  <Col xs={24} sm={7} md={5} lg={4}>
                    <label className="common-label">Delivery Total</label>
                    <Form.Item
                      name={`deliveryTotal$${tab}`}
                      dependencies={[
                        `deliverySell$${tab}`,
                        `deliveryBuy$${tab}`,
                      ]}
                      // initialValue={0}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let CurrentDeliveryTotal = getFieldValue(
                              `deliveryTotal$${tab}`
                            );
                            let fixBrokerage =
                              getFieldValue(`brokerageType$${tab}`) === "fixed";
                            if (
                              (!fixBrokerage &&
                                CurrentDeliveryTotal >= deliveryTotal &&
                                CurrentDeliveryTotal >= 0 &&
                                CurrentDeliveryTotal < 1) ||
                              (fixBrokerage &&
                                CurrentDeliveryTotal >= deliveryTotal &&
                                CurrentDeliveryTotal >= 0) ||
                              (fixBrokerage &&
                                (isMaster || isRent) &&
                                CurrentDeliveryTotal >= 0) ||
                              (!fixBrokerage &&
                                (isMaster || isRent) &&
                                CurrentDeliveryTotal >= 0 &&
                                CurrentDeliveryTotal < 1)
                            ) {
                              if (CurrentDeliveryTotal !== null) {
                                return Promise.resolve();
                              }
                            }
                            if (isMaster || isRent) {
                              return Promise.reject(
                                new Error(
                                  `Please add positive number ${
                                    fixBrokerage ? "" : `between 0 to 0.99`
                                  } `
                                )
                              );
                            }
                            return Promise.reject(
                              new Error(
                                `You can not give Delivery Total less then ${
                                  fixBrokerage
                                    ? deliveryTotal
                                    : `${deliveryTotal} and more then 0.99`
                                } `
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        name={`deliveryTotal$${tab}`}
                        step={0.01}
                        className="w-100"
                        addonAfter={brokerageFix ? "Fix" : "%"}
                        onChange={(e) => {
                          form.setFields([
                            {
                              name: `deliveryBuy$${tab}`,
                              value: e / 2,
                              touched: true,
                            },
                            {
                              name: `deliverySell$${tab}`,
                              value: e / 2,
                              touched: true,
                            },
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <EqualToCol xs={1} sm={1} md={2} lg={3}>
                    =
                  </EqualToCol>
                  <Col xs={24} sm={7} md={5} lg={4}>
                    <label className="common-label">Delivery Buy</label>
                    <Form.Item
                      name={`deliveryBuy$${tab}`}
                      dependencies={[
                        `deliverySell$${tab}`,
                        `deliveryTotal$${tab}`,
                      ]}
                      // initialValue={0}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let currentDeliveryBuy = getFieldValue(
                              `deliveryBuy$${tab}`
                            );
                            if (
                              (currentDeliveryBuy >= deliveryBuy &&
                                currentDeliveryBuy !== null &&
                                currentDeliveryBuy !== "" &&
                                currentDeliveryBuy <=
                                  getFieldValue(`deliveryTotal$${tab}`)) ||
                              ((isMaster || isRent) &&
                                currentDeliveryBuy !== null &&
                                currentDeliveryBuy !== "" &&
                                currentDeliveryBuy <=
                                  getFieldValue(`deliveryTotal$${tab}`))
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                getFieldValue(`deliveryTotal$${tab}`)
                                  ? `You can not give deliveryBuy less then ${deliveryBuy} and more then ${getFieldValue(
                                      `deliveryTotal$${tab}`
                                    )}
                                `
                                  : "Pleaser Enter Delivery Total"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        className="w-100"
                        name={`deliveryBuy$${tab}`}
                        step={0.01}
                        addonAfter={brokerageFix ? "Fix" : "%"}
                        onChange={(e) => {
                          if (form.getFieldValue(`deliveryTotal$${tab}`)) {
                            let diff =
                              form.getFieldValue(`deliveryTotal$${tab}`) - e;
                            form.setFields([
                              {
                                name: `deliverySell$${tab}`,
                                value: Number(diff.toFixed(4)),
                                touched: true,
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <EqualToCol xs={1} sm={1} md={2} lg={3}>
                    +
                  </EqualToCol>
                  <Col xs={24} sm={7} md={5} lg={4}>
                    <label className="common-label">Delivery Sell</label>
                    <Form.Item
                      name={`deliverySell$${tab}`}
                      dependencies={[
                        `deliveryBuy$${tab}`,
                        `deliveryTotal$${tab}`,
                      ]}
                      // initialValue={0}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let currentDeliverySell = getFieldValue(
                              `deliverySell$${tab}`
                            );
                            if (
                              (currentDeliverySell >= deliverySell &&
                                currentDeliverySell !== null &&
                                currentDeliverySell !== "" &&
                                currentDeliverySell <=
                                  getFieldValue(`deliveryTotal$${tab}`)) ||
                              ((isMaster || isRent) &&
                                currentDeliverySell !== null &&
                                currentDeliverySell !== "" &&
                                currentDeliverySell <=
                                  getFieldValue(`deliveryTotal$${tab}`))
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                getFieldValue(`deliveryTotal$${tab}`)
                                  ? `You can not give deliverySell less then ${deliverySell} and more then ${getFieldValue(
                                      `deliveryTotal$${tab}`
                                    )}
                                `
                                  : "Pleaser Enter Delivery Total"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        name={`deliverySell$${tab}`}
                        step={0.01}
                        className="w-100"
                        addonAfter={brokerageFix ? "Fix" : "%"}
                        onChange={(e) => {
                          if (form.getFieldValue(`deliveryTotal$${tab}`)) {
                            let diff =
                              form.getFieldValue(`deliveryTotal$${tab}`) - e;
                            form.setFields([
                              {
                                name: `deliveryBuy$${tab}`,
                                value: Number(diff.toFixed(4)),
                                touched: true,
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify={"center"} gutter={[8, 8]}>
                  <Col xs={24} sm={7} md={5} lg={4}>
                    <label className="common-label">Intraday Total</label>
                    <Form.Item
                      name={`intradayTotal$${tab}`}
                      dependencies={[
                        `intradayBuy$${tab}`,
                        `intradaySell$${tab}`,
                      ]}
                      // initialValue={0}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let currentIntradayTotal = getFieldValue(
                              `intradayTotal$${tab}`
                            );
                            let fixBrokerage =
                              getFieldValue(`brokerageType$${tab}`) === "fixed";
                            if (
                              (!fixBrokerage &&
                                currentIntradayTotal >= intraDayTotal &&
                                currentIntradayTotal >= 0 &&
                                currentIntradayTotal < 1) ||
                              (fixBrokerage &&
                                currentIntradayTotal >= intraDayTotal &&
                                currentIntradayTotal >= 0) ||
                              (!fixBrokerage &&
                                (isMaster || isRent) &&
                                currentIntradayTotal >= 0 &&
                                currentIntradayTotal < 1) ||
                              (fixBrokerage &&
                                (isMaster || isRent) &&
                                currentIntradayTotal >= 0)
                            ) {
                              if (currentIntradayTotal !== null) {
                                return Promise.resolve();
                              }
                            }
                            if (isMaster || isRent) {
                              return Promise.reject(
                                new Error(
                                  `Please add positive number ${
                                    fixBrokerage ? "" : `between 0 to 0.99`
                                  }`
                                )
                              );
                            }
                            return Promise.reject(
                              new Error(
                                `You can not give Intraday Total less then ${
                                  fixBrokerage
                                    ? intraDayTotal
                                    : `${intraDayTotal} and more then 0.99`
                                }`
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        name={`intradayTotal$${tab}`}
                        step={0.01}
                        className="w-100"
                        addonAfter={brokerageFix ? "Fix" : "%"}
                        onChange={(e) => {
                          form.setFields([
                            {
                              name: `intradayBuy$${tab}`,
                              value: e / 2,
                              touched: true,
                            },
                            {
                              name: `intradaySell$${tab}`,
                              value: e / 2,
                              touched: true,
                            },
                          ]);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <EqualToCol xs={1} sm={1} md={2} lg={3}>
                    =
                  </EqualToCol>
                  <Col xs={24} sm={7} md={5} lg={4}>
                    <label className="common-label">Intraday Buy</label>
                    <Form.Item
                      name={`intradayBuy$${tab}`}
                      dependencies={[
                        `intradayTotal$${tab}`,
                        `intradaySell$${tab}`,
                      ]}
                      // initialValue={0}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let currentIntradayBuy = getFieldValue(
                              `intradayBuy$${tab}`
                            );
                            if (
                              (currentIntradayBuy >= intradayBuy &&
                                currentIntradayBuy !== null &&
                                currentIntradayBuy !== "" &&
                                currentIntradayBuy <=
                                  getFieldValue(`intradayTotal$${tab}`)) ||
                              ((isMaster || isRent) &&
                                currentIntradayBuy !== null &&
                                currentIntradayBuy !== "" &&
                                currentIntradayBuy <=
                                  getFieldValue(`intradayTotal$${tab}`))
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                getFieldValue(`intradayTotal$${tab}`)
                                  ? `You can not give Intraday Buy less then ${intradayBuy} and more then ${getFieldValue(
                                      `intradayTotal$${tab}`
                                    )}
                                `
                                  : "Please Enter Intraday Total"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        name={`intradayBuy$${tab}`}
                        step={0.01}
                        className="w-100"
                        addonAfter={brokerageFix ? "Fix" : "%"}
                        onChange={(e) => {
                          if (form.getFieldValue(`intradayTotal$${tab}`)) {
                            let diff =
                              form.getFieldValue(`intradayTotal$${tab}`) - e;
                            form.setFields([
                              {
                                name: `intradaySell$${tab}`,
                                value: Number(diff.toFixed(4)),
                                touched: true,
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <EqualToCol xs={1} sm={1} md={2} lg={3}>
                    +
                  </EqualToCol>
                  <Col xs={24} sm={7} md={5} lg={4}>
                    <label className="common-label">Intraday Sell</label>
                    <Form.Item
                      name={`intradaySell$${tab}`}
                      dependencies={[
                        `intradayTotal$${tab}`,
                        `intradayBuy$${tab}`,
                      ]}
                      // initialValue={0}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let currnentIntradaySell = getFieldValue(
                              `intradaySell$${tab}`
                            );
                            if (
                              (currnentIntradaySell >= intradaySell &&
                                currnentIntradaySell !== null &&
                                currnentIntradaySell !== "" &&
                                currnentIntradaySell <=
                                  getFieldValue(`intradayTotal$${tab}`)) ||
                              ((isMaster || isRent) &&
                                currnentIntradaySell !== null &&
                                currnentIntradaySell !== "" &&
                                currnentIntradaySell <=
                                  getFieldValue(`intradayTotal$${tab}`))
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                getFieldValue(`intradayTotal$${tab}`)
                                  ? `You can not give Intraday Sell less then ${intradaySell} and more then ${getFieldValue(
                                      `intradayTotal$${tab}`
                                    )}
                                `
                                  : "Pleaser Enter Intraday Total"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        className="w-100"
                        name={`intradaySell$${tab}`}
                        step={0.01}
                        addonAfter={brokerageFix ? "Fix" : "%"}
                        onChange={(e) => {
                          if (form.getFieldValue(`intradayTotal$${tab}`)) {
                            let diff =
                              form.getFieldValue(`intradayTotal$${tab}`) - e;
                            form.setFields([
                              {
                                name: `intradayBuy$${tab}`,
                                value: Number(diff.toFixed(4)),
                                touched: true,
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row justify={"space-around"} gutter={[7, 7]}>
                <Col xs={24} sm={7} md={8} lg={8}>
                  <ThemeInputNumber
                    title="Percentage"
                    name={`percentage$${tab}`}
                    extraProps={{
                      addonAfter: "%",
                    }}
                    inputProps={{
                      name: `percentage$${tab}`,
                      disabled: canBlockPercentage,
                    }}
                    formProps={{
                      name: `percentage$${tab}`,
                      rules: [
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            value = getFieldValue(`percentage$${tab}`);
                            if (
                              (value <= pr &&
                                value !== null &&
                                value !== "" &&
                                value >= 0) ||
                              ((isMaster || isRent) &&
                                value >= 0 &&
                                value !== null &&
                                value !== "" &&
                                value <= 100) ||
                              canBlockPercentage
                            ) {
                              return Promise.resolve();
                            }
                            if (isMaster || isRent) {
                              return Promise.reject(
                                new Error(
                                  `Please fill this field with positive number between 0 to 100`
                                )
                              );
                            }
                            return Promise.reject(
                              new Error(
                                `You can not give Percentage less then 0 and more then ${pr}`
                              )
                            );
                          },
                        }),
                      ],
                    }}

                    // titleFontWeight={400}
                  />
                  {/* </Form.Item> */}
                </Col>
                <Col xs={24} sm={7} md={8} lg={8}>
                  <ThemeInputNumber
                    title="Delivery (Buy + Sell)"
                    extraProps={{
                      addonAfter: brokerageFix ? "Fix" : "%",
                    }}
                    inputProps={{
                      name: `deliveryTotal$${tab}`,
                      disabled: showBrokerage === false,
                    }}
                    titleFontWeight={400}
                    formProps={{
                      name: `deliveryTotal$${tab}`,
                      rules: [
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            value = getFieldValue(`deliveryTotal$${tab}`);
                            let fixBrokerage =
                              getFieldValue(`brokerageType$${tab}`) === "fixed";
                            if (
                              ((isMaster || isRent) &&
                                value >= 0 &&
                                value !== "" &&
                                value !== null &&
                                fixBrokerage) ||
                              ((isMaster || isRent) &&
                                value >= 0 &&
                                value !== "" &&
                                value !== null &&
                                value < 1 &&
                                !fixBrokerage) ||
                              (value >= deliveryTotal &&
                                value >= 0 &&
                                value !== null &&
                                value !== "" &&
                                fixBrokerage) ||
                              (value >= deliveryTotal &&
                                value >= 0 &&
                                value !== null &&
                                value !== "" &&
                                value < 1 &&
                                !fixBrokerage) ||
                              showBrokerage === false
                            ) {
                              return Promise.resolve();
                            }
                            if (isMaster || isRent) {
                              return Promise.reject(
                                new Error(
                                  `Please fill this field with positive number ${
                                    fixBrokerage ? "" : `between 0 to 0.99`
                                  } `
                                )
                              );
                            }
                            return Promise.reject(
                              new Error(
                                `You can not give delivery less then ${
                                  fixBrokerage
                                    ? deliveryTotal
                                    : `${deliveryTotal} and more then 0.99`
                                } `
                              )
                            );
                          },
                        }),
                      ],
                    }}
                  />
                </Col>
                <Col xs={24} sm={7} md={8} lg={8}>
                  <ThemeInputNumber
                    title="Intraday (Buy + Sell)"
                    step={0.001}
                    extraProps={{
                      input_type: "number",
                      addonAfter: brokerageFix ? "Fix" : "%",
                    }}
                    inputProps={{
                      name: `intradayTotal$${tab}`,
                      disabled: showBrokerage === false,
                    }}
                    titleFontWeight={400}
                    formProps={{
                      name: `intradayTotal$${tab}`,
                      rules: [
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            value = getFieldValue(`intradayTotal$${tab}`);
                            let fixBrokerage =
                              getFieldValue(`brokerageType$${tab}`) === "fixed";
                            if (
                              ((isMaster || isRent) &&
                                value >= 0 &&
                                value !== null &&
                                value !== "" &&
                                fixBrokerage) ||
                              ((isMaster || isRent) &&
                                value >= 0 &&
                                value !== null &&
                                value !== "" &&
                                value < 1 &&
                                !fixBrokerage) ||
                              (value >= intraDayTotal &&
                                value >= 0 &&
                                value !== null &&
                                value !== "" &&
                                fixBrokerage) ||
                              (value >= intraDayTotal &&
                                value >= 0 &&
                                value !== null &&
                                value !== "" &&
                                value < 1 &&
                                !fixBrokerage) ||
                              showBrokerage === false
                            ) {
                              return Promise.resolve();
                            }
                            if (isMaster || isRent) {
                              return Promise.reject(
                                new Error(
                                  `Please fill this field with positive number ${
                                    fixBrokerage ? "" : `between 0 to 0.99`
                                  } `
                                )
                              );
                            }
                            return Promise.reject(
                              new Error(
                                `You can not give delivery less then ${
                                  fixBrokerage
                                    ? intraDayTotal
                                    : `${intraDayTotal} and more then 0.99`
                                } `
                              )
                            );
                          },
                        }),
                      ],
                    }}
                  />
                </Col>
              </Row>
            )}
          </BrokerageDiv>
        </ThemeCard>
      </Form>
    );
  };

//   (prevProps, nextProps) => {
//     return (
//       `${prevProps?.form.getFieldValue(`brokerageType$${prevProps?.tab}`)}` ===
//       `${nextProps?.form.getFieldValue(`brokerageType$${nextProps?.tab}`)}`
//     );
//   }
// );

export default React.memo(ClientBrokerageView);
