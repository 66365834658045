import { Col, Row, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { options } from "../../../../Helper/constant";
import { AccountOpeningViewCol, Accounts } from "../style";
import ThemeTextLabel from "../../../../components/ThemeTextLabel";
import ThemeInputNumber, {
  converterFunction,
} from "../../../../components/ThemeInputNumber/ThemeInputNumber";

const AccountOpeningView = ({ is_edits, rent }) => {
  const token = theme.useToken().token;

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const accountDetails = useSelector(
    (state) => state.userAccountSlice.accountDetails
  );
  const parentDetails = useSelector(
    (state) => state.authSlice.MarketAccessDetails?.accountDetails
  );
  const parentBasicInfo = useSelector(
    (state) => state.authSlice?.MarketAccessDetails?.basicInfo
  );
  const constantValue = useSelector(
    (state) => state.globleSlice?.constantValue
  );

  const isMaster = accountType === "masterAdmin";

  //state
  const [accountOptions, setAccountOptions] = useState([]);

  // life cycle
  useEffect(() => {
    if (accountType) {
      let array = [];
      let allow = false;
      let authorityLevel = 0;
      options.user.accountType.forEach((element) => {
        if (element?.value === accountType) {
          authorityLevel = element?.authorityLevel;
          allow = true;
          return;
        }
        // if (authorityLevel < element?.authorityLevel) {
        //   array.push(element);
        // }
        if (allow) {
          array.push(element);
        }
      });
      setAccountOptions(array);
    }
  }, [accountType]);

  return (
    <Accounts>
      <Row align={"center"} style={{ marginBottom: "1rem" }}>
        <ThemeTextLabel
          fontSize={"1.4rem"}
          title="Allow No. of Account Openings"
          color={token.colorTextBase}
          titleWrapperStyle={{
            marginBottom: rent ? "2rem" : "1rem",
          }}
        />
      </Row>
      <Row
        align={"left"}
        gutter={[20, 10]}
        style={{ alignItems: "center", margin: "1rem" }}
      >
        {accountOptions?.map((item, index) => {
          return (
            <React.Fragment key={item?.value}>
              {!rent && (
                <AccountOpeningViewCol xs={24} sm={12} md={6} lg={6} xl={5}>
                  <ThemeTextLabel required title={item?.label} />
                </AccountOpeningViewCol>
              )}
              <Col
                xs={24}
                sm={12}
                md={rent ? 10 : 6}
                lg={rent ? 10 : 6}
                xl={rent ? 8 : 6}
              >
                <ThemeInputNumber
                  extraProps={{
                    input_type: "number",
                  }}
                  required
                  title={rent ? item?.label : ""}
                  inputProps={{
                    placeholder: "0",
                    name: `allowNoOfAccount${item?.value2}`,
                    step: 1,
                    suffix: is_edits
                      ? `/${converterFunction(
                          accountDetails?.historicalData?.[item?.value2]
                        )}`
                      : null,
                  }}
                  formProps={{
                    name: `allowNoOfAccount${item?.value2}`,
                    rules: [
                      () => ({
                        validator(_, value) {
                          if (
                            (is_edits &&
                              isMaster &&
                              value >= 0 &&
                              value !== "" &&
                              value !== null) ||
                            (is_edits &&
                              !isMaster &&
                              value <=
                                parentDetails[
                                  `allowNoOfAccount${item?.value2}`
                                ] +
                                  constantValue?.[
                                    `allowNoOfAccount${item?.value2}`
                                  ] &&
                              value !== "" &&
                              value !== null) ||
                            (isMaster &&
                              !is_edits &&
                              value >= 0 &&
                              value !== "" &&
                              value !== null) ||
                            (!is_edits &&
                              value !== "" &&
                              value !== null &&
                              value <=
                                parentDetails[
                                  `allowNoOfAccount${item?.value2}`
                                ])
                          ) {
                            return Promise.resolve();
                          } else {
                            if (isMaster) {
                              return Promise.reject(
                                `Please fill allow number of account opening of ${item?.value2} `
                              );
                            }
                            if (
                              parentDetails[
                                `allowNoOfAccount${item?.value2}`
                              ] === 0 &&
                              !is_edits
                            ) {
                              return Promise.reject(
                                `${parentBasicInfo.accountName}(${parentBasicInfo.userId}) don't have enough number of ${item?.value2} accounts, Please enter 0`
                              );
                            }
                            if (is_edits) {
                              return Promise.reject(
                                `You cannot allow a number of account openings for ${
                                  item?.value2
                                } less than 0 or more than  ${
                                  constantValue?.[
                                    `allowNoOfAccount${item?.value2}`
                                  ] +
                                  parentDetails[
                                    `allowNoOfAccount${item?.value2}`
                                  ]
                                } `
                              );
                            } else {
                              return Promise.reject(
                                `You cannot allow a number of account openings for ${
                                  item?.value2
                                } less than 0 or more than ${
                                  parentDetails[
                                    `allowNoOfAccount${item?.value2}`
                                  ]
                                }`
                              );
                            }
                          }
                        },
                      }),
                    ],
                  }}
                  marginBottom={"0px !important"}
                />
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </Accounts>
  );
};

export default React.memo(AccountOpeningView);
