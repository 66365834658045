import {
  Col,
  Form,
  Modal,
  Radio,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  width: 80% !important;
  /* height: 500px !important; */
  .ant-modal-close-x {
    /* display: none; */
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    @media (max-width: 650px) {
      padding: 15px 5px !important;
    }
  }

  @media (max-width: 650px) {
    width: 95% !important;
    /* padding: 5px; */
  }
`;
export const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;
export const StyledTabs = styled(Tabs)`
  /* background-color: red; */
  font-size: 16px;
  padding: 16px;
  .ant-tabs-ink-bar {
    background-color: #1677ff;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1677ff !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
  }
  .ant-tabs-nav-list {
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-tabs-tab-btn {
    color: #727880;
    font-weight: 500;
    font-size: 1.3rem !important;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  @media (max-width: 1060px) {
    .ant-tabs-nav-wrap {
      justify-content: left;
    }
  }
`;
export const FilterText = styled(Typography.Text)`
  cursor: pointer;
  display: flex;
  align-items: baseline;
  text-decoration: underline;
  margin-top: 3.5rem;
  color: ${(props) => props.theme.token.blue};

  @media (max-width: 575px) {
    margin-top: 0rem;
  }
`;
export const EditButtonHold = styled.div`
  /* margin-top: auto; */
  width: 80%;
  align-self: center;
  border-radius: 2px;
  border: ${(props) =>
    props?.isSquareUp ? "2px solid red" : "2px solid #a5cb1c"};
`;
export const RotatedSwitch = styled(Switch)`
  transform: rotate(90deg);
  width: 30%;
  margin: 0;
  align-self: center;
`;
export const WrraperDiv = styled.div`
  margin-top: 1rem;
  border-radius: 10px;
  background-color: #fff;
  padding: 1rem 0rem;
`;
export const StyledSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
`;
export const EntriesDiv = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #727880;
  margin-right: 1rem;
`;
export const UplineTag = styled(Tag)`
  font-size: 1rem;
  margin-left: 0.5rem;
`;
export const option = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "40", label: "40" },
];
export const UserStatusOptions = [
  { label: "Active", value: "Active" },
  { label: "Block", value: "Block" },
];
export const LoginStatusOptions = [
  { label: "Login", value: "Login" },
  { label: "Logout", value: "Logout" },
];
export const AccountTypeRadioButton = styled(Radio.Group)`
  width: 100%;

  text-align: center;
  & .ant-radio-button-wrapper {
    width: 33%;
    font-size: 1.1rem;
  }
`;
export const ResetPassForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;
export const ActionButtonsDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;
export const EditButtonText = styled.div`
  margin-top: ${({ squareUp }) => (squareUp ? "3px" : "0px")};
  margin-bottom: ${({ squareUp }) => (squareUp ? "3px" : "0px")};
`;

export const UserText = styled(Typography.Text)`
  margin: 0px;
  line-height: unset;
`;
export const UserInfoDiv = styled.div`
  display: flex;
`;
export const TextDiv = styled.div`
  display: flex;
  text-align: left;
  align-content: center;
`;
export const TypeSpace = styled(Space)`
  margin-left: auto;
`;
export const StyledCol = styled(Col)`
  margin-top: 1.5rem;
  margin-left: ${(props) => (props?.left === "auto" ? "auto" : 0)};
`;
export const accountOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Normal",
    value: "default",
  },
  { label: "Rent", value: "rent" },
];

export const brokerageColumns = [
  {
    title: "Market",
    key: "market",
    dataIndex: "market",
    //   width: 100,
    render: (text, record) => <div>{record?.marketAccessId?.scriptName}</div>,
  },
  {
    title: "Delivery",
    dataIndex: "deliveryTotal",
    key: "deliveryTotal",
    //   width: 100,
    render: (text, record) => <div>{record?.deliveryTotal}</div>,
  },
  {
    title: "Intraday",
    key: "intradayTotal",
    dataIndex: "intradayTotal",
    //   width: 100,
    render: (text, record) => <div>{record?.intradayTotal}</div>,
  },
];
export const accountColumns = [
  {
    title: "Account",
    key: "accountType",
    width: 100,
    render: (text, record) => <div>{record?.name}</div>,
  },
  {
    title: "Remaining",
    key: "remaining",
    width: 100,
    render: (text, record) => <div>{record?.remaining}</div>,
  },
  {
    title: "Total",
    key: "history",
    width: 100,
    render: (text, record) => <div>{record?.total}</div>,
  },
];
