import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  "NSE FUT": { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  "NSE OPT": { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  "NSE EQU": { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  MCX: { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  "MCX OPT": { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  FOREX: { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  COMEX: { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  GLOBEX: { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
  CRYPTO: { edited: [], blocked: [], staticBlocked: [], preBlock: [] },
};

const scriptSettingsRequestSlice = createSlice({
  name: "scriptSettingsRequestSlice",
  initialState,
  reducers: {
    setBlockedScripts: (state, action) => {
      const { tab, blocked, previous } = action.payload;
      state[tab].blocked = blocked || [];
    },
    addAllMarketBlockedScript: (state, action) => {
      const { data } = action.payload;
      for (let key in data) {
        if (state[key]) {
          state[key].blocked = data[key] || [];
          state[key].preBlock = data[key] || [];
        }
      }
    },
    setContentBlockScript: (state, action) => {
      const { data } = action.payload;
      for (let key in data) {
        if (state[key]) {
          state[key].staticBlocked = data[key];
        }
      }
    },

    setEditData: (state, action) => {
      return {
        ...state,
        [action.payload.tab]: {
          ...state[action.payload.tab],
          blocked: state[action.payload.tab].blocked || [],
          edited: action.payload?.edited,
        },
      };
    },
    removeBlockedAndEdited: (state, action) => {
      return { ...initialState };
    },
  },
});

export default scriptSettingsRequestSlice.reducer;

export const scriptSettingRequestSelector = (state) =>
  state.scriptSettingsRequestSlice;

export const {
  setBlockedScripts,
  setEditData,
  removeBlockedAndEdited,
  addAllMarketBlockedScript,
  setContentBlockScript,
} = scriptSettingsRequestSlice.actions;
