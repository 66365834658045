import { ReloadOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Skeleton, Spin } from "antd";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../pages/Dashboard/style.css";
import { useSelector } from "react-redux";
import { RootDiv, StyledTheme, columnSpan } from "./style";

function DashboardCommonTable(props) {
  const {
    tradeQuery,
    dataSource,
    isLoading,
    queryKey,
    columns,
    onChange,
    emptyPage,
  } = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const tableRef = useRef(null);
  const lastRowRef = useRef(null);
  const scrollRef = useRef(null);
  const loginUserId = useSelector(
    (state) => state?.authSlice?.userDetail?.userId
  );

  const handleObserver = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          tradeQuery?.hasNextPage &&
          !tradeQuery.isFetchingNextPage
        ) {
          tradeQuery?.fetchNextPage();
        }
      });
    },
    [
      tradeQuery?.fetchNextPage,
      tradeQuery?.hasNextPage,
      tradeQuery?.isFetchingNextPage,
    ]
  );

  // life cycle
  // useEffect(() => {
  //   const handleTableWheelScroll = (e) => {
  //     const tableBody = scrollRef.current?.querySelector(".ant-table-body");

  //     if (tableBody && tableBody.contains(e.target)) {
  //       const scrollTop = tableBody.scrollTop;
  //       const maxScrollTop = tableBody.scrollHeight - tableBody.clientHeight;

  //       // Prevent default scrolling only when table can scroll further
  //       if (
  //         (e.deltaY < 0 && scrollTop > 0) || // Scroll up when not at the top
  //         (e.deltaY > 0 && scrollTop < maxScrollTop) // Scroll down when not at the bottom
  //       ) {
  //         e.preventDefault();
  //         tableBody.scrollTop += e.deltaY * 2; // Manually scroll the table
  //       }
  //     }
  //   };

  //   // Attach event listener to table body for mouse scroll
  //   const tableBody = scrollRef.current?.querySelector(".ant-table-body");
  //   if (tableBody) {
  //     tableBody.addEventListener("wheel", handleTableWheelScroll, {
  //       passive: false, // Allow preventDefault to work
  //     });
  //   }

  //   // Cleanup the event listener on unmount
  //   return () => {
  //     if (tableBody) {
  //       tableBody.removeEventListener("wheel", handleTableWheelScroll);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: tableRef.current,
      rootMargin: "50px",
      threshold: 0.3,
    });

    // Determine the last row of the current data and observe it
    const rows = tableRef.current?.querySelectorAll(".ant-table-row");

    const lastRow = rows && rows?.length >= 10 ? rows[rows.length - 1] : null;
    if (lastRow && !emptyPage) {
      observer.observe(lastRow);
      lastRowRef.current = lastRow; // Update the last row reference
    }

    return () => {
      if (lastRowRef.current) observer.unobserve(lastRowRef.current);
    };
  }, [dataSource, tradeQuery]);

  // function
  const loadingComponant = () => (
    <tr>
      <td colSpan={columnSpan(queryKey)}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      </td>
    </tr>
  );
  const handleReload = () => {
    queryClient.resetQueries({ queryKey: [props?.queryKey] });
  };
  const navigator = () => {
    switch (queryKey) {
      case "allTrade":
        navigate("/trading/trade");
        break;
      case "pendingTrade":
        navigate(`/trading/trade?userId=${loginUserId}&tradeStatus=pending`);
        break;
      case "rejectionLogs":
        navigate("/utilities/rejection-log");
        break;
      case "m2mAlerts":
        navigate("/utilities/M2mAlert-Log");
        break;
    }
  };

  return (
    <RootDiv ref={scrollRef} querykey={queryKey}>
      <StyledTheme.MainHeader>
        <StyledTheme.Title>{props && props?.tableTitle}</StyledTheme.Title>
        <StyledTheme.RightHeaderWrapper>
          {props?.allowBtn ? (
            <StyledTheme.AllowButton block type="primary" htmlType="submit">
              Allow
            </StyledTheme.AllowButton>
          ) : null}
          <StyledTheme.ViewMore onClick={navigator}>
            {props?.viewMore ? "View More>>" : ""}
          </StyledTheme.ViewMore>
          <StyledTheme.ReloadSpan onClick={handleReload}>
            <ReloadOutlined style={{ cursor: "pointer" }} />
          </StyledTheme.ReloadSpan>
        </StyledTheme.RightHeaderWrapper>
      </StyledTheme.MainHeader>

      <StyledTheme.TableWrapper ref={tableRef}>
        <StyledTheme.Table
          // virtual
          columns={columns}
          dataSource={dataSource || []}
          pagination={false}
          onChange={onChange}
          sticky
          locale={{
            emptyText: isLoading ? <Skeleton active /> : undefined,
          }}
          rowKey="_id"
          summary={() => tradeQuery?.isFetchingNextPage && loadingComponant()}
          scroll={{
            x:
              isLoading || dataSource?.length === 0 ? undefined : "max-content",
            y: 225,
          }}
        />
        {/* <div style={{ textAlign: "center", margin: "10px 0" }}>
          {tradeQuery?.isFetchingNextPage && <Spin />}
        </div> */}
      </StyledTheme.TableWrapper>
    </RootDiv>
  );
}

export default React.memo(DashboardCommonTable); //DashboardCommonTable;
