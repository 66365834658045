import { Col, Typography } from "antd";
import styled from "styled-components";

export const FilterColumn = styled(Col)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  align-content: center;
`;

export const FilterText = styled(Typography.Text)`
  margin: 0px;
  font-size: 1.1rem;
  display: flex;
  align-self: center;
`;
