const EndPoints = {
  login: "/users/login",
  register: "/users/register",
  checkserver: "/users/",
  getAllUser: "/users/get-all-users",
  searchCustomer: "/users/get-all-customer/",
  getMarketList: "/script-data/get-market-list",
  getScriptList: "/script-data/get-script-list-web?market=",
  getStrikePrice: "/script-data/get-strike-price",
  getExpiryDateList: "/script-data/get-expiry-date",
  addScript: "/market-script/add-script",
  addAllScript: "/market-script/add-all-script?market=",
  addNewExpiryScript: "/market-script/add-new-expiry-script?market=",
  getUser: "/users/get-users/",
  getUserByFilter: "/users/get-filter-users?userType=",
  getChildUsers: "/users/get-child-user?id=",
  deleteScript: "/market-script/delete-script?",
  getWatchList: "/market-script/get-watch-list",
  createTrade: "/trade/create-trade",
  getTrade: "/trade/get-trade-web",
  tradePosition: "/position/user-positions",
  deleteTrade: "/trade/delete-trade",
  resetPassward: "/users/reset-password",
  editUsers: "/users/update-user/",
  tradeLogs: "/get-user-trade-logs",
  filterTrade: "/trade/filter-trade",
  getMarketStatus: "/market-status/",
  // rejectionLogs: "/users/get-child-rejection-logs-list",
  editTrade: "trade/edit-trade?id=",
  getAllScript: "/market-script/add-all-script",
  margin: "/users/get-user-margin",
  userValanBill: "/valan/user-valan-bill?userId=",
  getBrokerage: "/users/get-brokerage-report",
  passwordVerification: "/users/password-verification",
  summaryReport: "/valan/user-summary-report",
  getValanList: "/valan/get-valan-list",
  createLedger: "/ledger/create-ledger",
  getLedger: "/ledger/get-ledger",
  updateLedger: "/ledger/",
  closePosition: "/position/close-position",
  rollOverPosition: "/position/roll-over-position",
  getUpline: "/users/upline-users?userId=",
  getPNLReport: "/users/get-pnl-report?",
  getM2MAlert: "/alerts/get-alerts?directCustomers=true",
  getDefaultUser: "/users/get-default-user-payload?user=",
  getTableScript: "/users/get-all-bar?marketName=",
  // getTradeLog: "/users/get-user-trade-logs",
  getTradeLog: "/users/get-trade-logs",
  banScriptList: "/market-script/ban-script-list",
  getExpirySetting: "/script-data/get-expiry-setting",
  addExpirySetting: "/script-data/expiry-setting",
  getBulkTrade: "/trade/bulk-trading-data",
  exucuteRejectedTrade: "/trade/execute-rejected-trade?tradeLogId=",
  userLogout: "users/logout?deviceType=web",
  addWatchList: "/market-script/create-watch-list",
  deleteWatchList: "/market-script/delete-watch-list?id=",
  restoreAutoSquareOff: "/trade/revert-auto-square-up?alertId=",
  editWatchList: "/market-script/edit-watch-list?id=",
  getUserStatus: "users/get-user-status?userId=",
  updateUserStatus: "users/update-user-status/",
  deleteUser: "users/delete-user?userId=",
  getPointLedgerLog: "logs/get-logs/ledger",
  getBrokerSharingReport: "/valan/broker-summary-report",
  getApplicationLink: "/users/check-status-server",
  getUserEditLog: "/users/get-user-edit-logs",
  deleteValan: "users/delete-user-valan?&valanId=",
  usersIpAddress: "users/ip-address-report",
  recalculateLedger: "ledger/get-ledger-recalculate",
  calculateMargin: "margin/calculate-margin",
  updateCSV: "ledger/update-csv",
  // getMargin: "/users/get-user-margin",
};

export default EndPoints;
